import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./ProductComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

import SwiperCore from "swiper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import noImageAvailable from "../../assets/images/images.png";
import { useApiRequest } from "../../core/api/useApiRequest";
import { useTranslation } from "react-i18next";
import ProductContext from "../../core/context/ProductContext";
import SelectOptionWrapper from "../SelectOption/SelectOptionWrapper";
import ModalWrapper from "../Modals/Modal.wrapper";
import ChangeName from "../Modals/ChangeName/ChangeName";
import NormalModal from "../Modals/Modal/NormalModal";
import { uniqueID, useNotification } from "../../core/context/notifications/NotificationProvider";
import LoadingState from "../LoadingState/LoadingState";
interface Variant {
  img_preview: string;
  name: string;
  size: string;
}
const selectStyle = {
  position: "absolute",
  bottom: " 35px",
  left: "-3px",
};
// interface IProduct {
//   wariant?: { [key: string]: any }[];
//   product_name?: string;
//   size?: string;
// }

interface IProductComponent {
  product: any;
  handleClick: (variant: Variant | null) => void;
  variant: "2ex_small" | "ex_small" | "small" | "medium" | "large";
  componentOptions: string;
  isActive?: any;
  setActiveProduct?: any;
  id?: any;
  isLoading?: boolean;
}
const ProductComponent: React.FC<IProductComponent> = ({
  product,
  handleClick,
  variant,
  componentOptions,
  id,
  isLoading: isLoadingFetchProduct,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const { setForceReloadFlag } = useContext(ProductContext);
  const { t } = useTranslation();
  const [openMore, setOpenMore] = useState<boolean>(false);
  const [openChangeNameModal, setOpenChangeNameModal] = useState<boolean>(false);
  const { sendRequest } = useApiRequest();
  const [productInfo, setProductInfo] = useState({
    name: "",
    size: "",
  });

  useEffect(() => {
    if (product?.pages?.length > 0) {
      setProductInfo({
        name: product?.template?.views?.name,
        size: product?.pages[0]?.size,
      });
    }
  }, [product]);

  const handleSelectProduct = (selectedView: any, event: any) => {
    event.stopPropagation();
    // setStyleIsActive(id);

    handleClick(selectedView);
  };

  const goNext = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    swiperRef.current?.slideNext();
  };

  const goPrev = (event: React.MouseEvent<SVGSVGElement>) => {
    event?.stopPropagation();
    swiperRef?.current?.slidePrev();
  };

  // Update productInfo based on the currently active slide
  const handleSlideChange = () => {
    const activeIndex: any = swiperRef.current?.activeIndex;
  };
  const notification = useNotification();

  const deleteProduct = async () => {
    try {
      const response = await sendRequest("delete", `/creator/2d/templates/${product.id}`);
      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Product Deleted",
      });
    } catch (err: any) {
      console.error(err);
    } finally {
      setForceReloadFlag((prev: boolean) => !prev);
      setOpenMore((prevState) => !prevState);
    }
  };
  const changeName = async () => {
    setOpenChangeNameModal((prevState) => !prevState);
  };

  const options = [
    {
      function: deleteProduct,
      name: t("Delete"),
    },
    {
      function: changeName,
      name: t("ChangeNameModal.Header"),
    },
  ];
  return (
    <>
      <div
        className={`${s.container} ${
          variant === "2ex_small"
            ? s.two_ex_small
            : variant === "ex_small"
            ? s.ex_small
            : variant === "small"
            ? s.small
            : variant === "medium"
            ? s.medium
            : s.large
        }  `}
      >
        <ComponentBackground id={id}>
          <div
            className={`${s.img_container} ${
              variant === "2ex_small"
                ? s.two_ex_small
                : variant === "ex_small"
                ? s.ex_small
                : variant === "small"
                ? s.small
                : variant === "medium"
                ? s.medium
                : s.large
            }`}
          >
            <Swiper
              modules={[Navigation]}
              className="mySwiper"
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              onSlideChange={handleSlideChange} // Handle slide changes
            >
              {product?.views?.map((el: any, i: any) => (
                <SwiperSlide
                  className={s.swiper_slide}
                  key={`dashboard-product-variant-${i}`}
                  onClick={(e) => handleSelectProduct(el, e)}
                >
                  {isLoadingFetchProduct ? (
                    <LoadingState />
                  ) : (
                    <img src={el?.thumbnail ? el.thumbnail : noImageAvailable} alt={el?.name} />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            {product?.views?.length > 1 ? (
              <>
                <ChevronLeftIcon className={s.l} fontSize="large" onClick={goPrev} />
                <ChevronRightIcon className={s.r} fontSize="large" onClick={goNext} />
              </>
            ) : null}
          </div>
        </ComponentBackground>
        {componentOptions === "1" ? (
          <SelectOptionWrapper
            options={options}
            openMore={openMore}
            setOpenMore={setOpenMore}
            style={selectStyle}
          />
        ) : null}

        <div className={s.info}>
          <span>{product?.name}</span>
          <span></span>
        </div>
      </div>
      <NormalModal open={openChangeNameModal} setOpen={setOpenChangeNameModal}>
        <ModalWrapper setOpen={setOpenChangeNameModal}>
          <ChangeName
            url={"templates"}
            currentJSON={product}
            setCloseModal={setOpenChangeNameModal}
          />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default ProductComponent;
