import React, { useState } from "react";
import s from "./Payments.module.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
const Payments = () => {
  const [age, setAge] = useState<string>("");

  return (
    <div className={s.container}>
      <div className={s.left}>
        <h4>Metoda Płatnośći</h4>
        <TextField label="Karta kredytowa lub debetowa" />
        <div className={s.icon_container}>
          <CreditCardIcon />
          <CreditCardIcon />
          <CreditCardIcon />
        </div>
        <TextField label="Visa *7777" />
        <div className={s.input_group}>
          <TextField label="01" />
          <TextField label="2023" />
        </div>
        <TextField label="Videlink SPO" />
      </div>

      <div className={s.right}>
        <div className={s.header}>
          <span>VIDELINK</span>
          <span>Business</span>
        </div>
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            style={{ textAlign: "center" }}
          >
            Search
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            size="small"
            // onChange={handleChange}
          >
            <MenuItem value={10}>10GB</MenuItem>
            <MenuItem value={20}>20GB</MenuItem>
            <MenuItem value={30}>50GB</MenuItem>
          </Select>
        </FormControl>

        <div className="solid-line"></div>

        <div className={s.info}>
          <div>
            <span>Suma częściowa</span>
            <span>249,00 PLN/mc.</span>
          </div>
          <div>
            <span>VAT 23%</span>
            <span>57.27 PLN/mc.</span>
          </div>
          <br />
          <div>
            <span className={s.highlight}>RAZEM</span>
            <span className={s.highlight}>
              306,27 PLN/mc. <br />
              <span>z VAT</span>
            </span>
          </div>
        </div>
        <div className="solid-line"></div>

        <span>
          Nastepna płatność: <strong>16 listopad 2019</strong>
        </span>
      </div>
    </div>
  );
};

export default Payments;
