import React from "react";
import s from "./DCWrapper.module.scss";
interface IDCWrapper {
  children: React.ReactNode;
}
const DCWrapper = ({ children }: IDCWrapper) => {
  return <div className={s.container}>{children}</div>;
};

export default DCWrapper;
