import { createContext, ReactNode, useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { useGetRequest } from "../api/useGetRequest";
import AuthContext from "./AuthContext";
import FabricJSContextNew from "./FabricJSContextNew";
import { useApiRequest } from "../api/useApiRequest";

interface GTextContextValue {
  text: [];
  setText: (graphics: any) => void;
  forceReloadFlag: boolean;
  setForceReloadFlag: (flag: boolean | ((prevFlag: boolean) => boolean)) => void;
  isLoadingFetchProduct: boolean;
}

const TextContext = createContext<GTextContextValue>({
  text: [],
  setText: () => {},
  forceReloadFlag: false,
  setForceReloadFlag: () => {},
  isLoadingFetchProduct: true,
});

export const TextContextProvider = ({ children }: { children: ReactNode }) => {
  const [text, setText] = useState<any>(null);
  const [forceReloadFlag, setForceReloadFlag] = useState<boolean>(false);
  const { sendRequest, isLoading: isLoadingFetchProduct } = useApiRequest();
  const { user } = useContext(AuthContext);

  const fetchGraphics = async () => {
    if (!user) return;
    const response: any = await sendRequest("get", "/media/text");
    setText(response?.data);
  };

  useEffect(() => {
    fetchGraphics();
  }, [forceReloadFlag, user]);
  return (
    <TextContext.Provider
      value={{
        text,
        setText,
        forceReloadFlag,
        setForceReloadFlag,
        isLoadingFetchProduct,
      }}
    >
      {children}
    </TextContext.Provider>
  );
};

export default TextContext;
