import React, { useState, useEffect } from "react";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { useTranslation } from "react-i18next";
import Folder from "../Folder/Folder";
import s from "./ExpandableFolderList.module.scss";
import { motion, AnimatePresence } from "framer-motion";

interface IFolder {
  id: number;
  name: string;
  parent_id: number;
  children?: [];
}

interface ExpandableFolderListProps {
  requestPath: string;
  setSelectedFolder: (folder: any) => void;
  selectedFolder: any;
}

const folderCustomeStyle = { width: "100%" };

const ExpandableFolderList = ({
  requestPath,
  setSelectedFolder,
  selectedFolder,
}: ExpandableFolderListProps) => {
  const { sendRequest } = useApiRequest();
  const [treeData, setTreeData] = useState<TreeViewBaseItem[]>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [selectedNodeLabel, setSelectedNodeLabel] = useState<string>("");
  const [selectedNode, setSelectedNode] = useState<TreeViewBaseItem | null>(null);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const { t } = useTranslation();
  const [isTreeVisible, setIsTreeVisible] = useState(false);

  const toggleTreeVisibility = () => {
    setIsTreeVisible((prev) => !prev);
  };

  const fetchFolders = async () => {
    try {
      const response: any = await sendRequest("get", `creator/2d/${requestPath}/categories`);
      const data: IFolder[] = response.data;

      const treeItems = buildTreeData(data);
      setTreeData(treeItems);

      // Collect all node IDs for expanding all nodes
      const allNodeIds = getAllNodeIds(treeItems);
      setExpandedItems(allNodeIds);

      setSelectedNode(treeItems[0]);
      setSelectedFolder(treeItems[0]);
      setSelectedNodeLabel(treeItems[0].label);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  useEffect(() => {
    fetchFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildTreeData = (items: IFolder[]): TreeViewBaseItem[] => {
    const idToNodeMap: { [key: number]: TreeViewBaseItem } = {};

    items.forEach((item) => {
      idToNodeMap[item.id] = { id: item.id.toString(), label: item.name, children: [] };
    });

    const roots: TreeViewBaseItem[] = [];

    items.forEach((item) => {
      const node = idToNodeMap[item.id];
      if (item.parent_id === 0) {
        roots.push(node);
      } else {
        const parent = idToNodeMap[item.parent_id];
        if (parent) {
          parent.children!.push(node);
        } else {
          console.warn(`Parent with ID ${item.parent_id} not found for item ${item.id}`);
        }
      }
    });

    const rootFolder: TreeViewBaseItem = {
      id: "0",
      label: "Root",
      children: roots,
    };

    return [rootFolder];
  };

  const getAllNodeIds = (nodes: TreeViewBaseItem[]): string[] => {
    let ids: string[] = [];
    nodes.forEach((node) => {
      ids.push(node.id);
      if (node.children && node.children.length > 0) {
        ids = ids.concat(getAllNodeIds(node.children));
      }
    });
    return ids;
  };

  const getItemId = (item: TreeViewBaseItem) => {
    return item.id;
  };

  const handleSelectedItemsChange = (event: React.SyntheticEvent, itemId: string | null) => {
    setSelectedNodeId(itemId);

    if (itemId == null) {
      setSelectedNode(null);
      setSelectedNodeLabel("");
      setSelectedFolder(0);
    } else {
      const findNode = (nodes: TreeViewBaseItem[]): TreeViewBaseItem | null => {
        for (let node of nodes) {
          if (node.id === itemId) {
            return node;
          }
          if (node.children && node.children.length > 0) {
            const foundNode = findNode(node.children);
            if (foundNode) return foundNode;
          }
        }
        return null;
      };

      const node = findNode(treeData);
      setSelectedNode(node);
      setSelectedFolder(node);
      setSelectedNodeLabel(node?.label || "");
    }
  };

  return (
    <>
      <Folder
        key={selectedFolder?.id}
        handleClick={toggleTreeVisibility}
        folder={selectedFolder}
        path={requestPath}
        customeStyle={folderCustomeStyle}
        withTreeList={true}
        isTreeVisible={isTreeVisible}
      />
      <AnimatePresence>
        {isTreeVisible && (
          <motion.div
            className={s.tree_container}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <RichTreeView
              items={treeData}
              selectedItems={selectedNodeId}
              onSelectedItemsChange={handleSelectedItemsChange}
              multiSelect={false}
              getItemId={getItemId}
              expandedItems={expandedItems}
              onExpandedItemsChange={(event, items) => {
                setExpandedItems((prevExpandedItems) => {
                  const newExpandedItems = Array.from(new Set([...prevExpandedItems, ...items]));
                  return newExpandedItems;
                });
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ExpandableFolderList;
