import React, { useContext } from "react";
import s from "./ProductList.module.scss";

import ProductComponent from "../../../components/ProductComponent/ProductComponent";
import DashboardTopHeader from "../../../components/DashboardTopHeader/DashboardTopHeader";
import { useNavigate } from "react-router-dom";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { useTranslation } from "react-i18next";
import ProductContext from "../../../core/context/ProductContext";
const ProductList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveGlobalState } = useContext(FabricJSContextNew);

  const { products } = useContext(ProductContext);

  const projectHandler = (selectedView: any, fullJSON: any) => {
    setActiveGlobalState({ canvasType: "product", fullJSON: fullJSON });

    // const productToShow = filterViewToShow(selectedView?.uuid, fullJSON);
    // handleLoadCanvasProject(canvasContext, productToShow.canvasJSON);
    navigate(`/design-studio/product/${fullJSON.token}`);
  };

  return (
    <div className={s.container}>
      <DashboardTopHeader header={t("Products")} navigateTo={"/products"} />
      <div className={s.list_container}>
        {products?.map((el: any, i: number) => {
          // if (i <= 3) {
          return (
            <ProductComponent
              key={`Dashboard-product-list${i}`}
              product={el}
              handleClick={(variant) => projectHandler(variant, el)}
              variant="medium"
              componentOptions="1"
              id={`Dashboard-product-list${i}`}
            />
          );
          // }
          // return null;
        })}
      </div>
    </div>
  );
};

export default ProductList;
