import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { IFolderData } from "../../types/generalTypes";
import { useLocation } from "react-router-dom";

interface TAppContext {
  children: ReactNode;
}

interface AppContextValue {
  openNav: boolean;
  setOpenNav: React.Dispatch<React.SetStateAction<boolean>>;
  currentFolder: IFolderData | null;
  setCurrentFolder: React.Dispatch<React.SetStateAction<IFolderData | null>>;
  breadcrumbs: string[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<string[]>>;
  forceFetchFolder: boolean;
  setForceFetchFolder: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingSave: boolean;
  setIsLoadingSave: React.Dispatch<React.SetStateAction<boolean>>;
  lastDesignStudioLink: string;
  setLastDesignStudioLink: React.Dispatch<React.SetStateAction<string>>;
  isLoadingDesignStudio: boolean;
  setIsLoadingDesignStudio: React.Dispatch<React.SetStateAction<boolean>>;
  folderPath: any;
  setFolderPath: any;
  navFolderStructure: any;
  setNavFolderStructure: any;
  openLeftBar: boolean;
  setOpenLeftBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContext = createContext<AppContextValue>({
  openNav: true,
  setOpenNav: () => {},
  breadcrumbs: [],
  setBreadcrumbs: () => {},

  currentFolder: null,
  setCurrentFolder: () => {},
  forceFetchFolder: false,
  setForceFetchFolder: () => {},
  isLoadingSave: false,
  setIsLoadingSave: () => {},
  lastDesignStudioLink: "",
  setLastDesignStudioLink: () => {},

  isLoadingDesignStudio: false,
  setIsLoadingDesignStudio: () => {},
  folderPath: [],
  setFolderPath: () => {},
  navFolderStructure: [],
  setNavFolderStructure: () => {},
  openLeftBar: true,
  setOpenLeftBar: () => {},
});

export const AppContextProvider = ({ children }: TAppContext) => {
  // const location = useLocation();
  const [openNav, setOpenNav] = useState<boolean>(true);

  const [openLeftBar, setOpenLeftBar] = useState<boolean>(false);

  // useEffect(() => {
  //   setOpenLeftBar(false);
  // }, [location]);

  const [currentFolder, setCurrentFolder] = useState<IFolderData | null>(null);

  const [forceFetchFolder, setForceFetchFolder] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);

  const [folderPath, setFolderPath] = useState<any[]>([]);

  //isLadingSave is used to display lading state inside toptoolswrapper when we are saving project or product
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

  const [isLoadingDesignStudio, setIsLoadingDesignStudio] = useState<boolean>(false);
  // this state is to keep track of the last used product project, so when we click on design-studio
  //to create stuff we fetch last used product or porject
  const [lastDesignStudioLink, setLastDesignStudioLink] = useState("");
  useEffect(() => {
    console.log(lastDesignStudioLink);
  }, [lastDesignStudioLink]);
  //nav folder structure is set inside Project/Product Dashboard component
  //it is just a list of folders that is being displayed inside the navigation
  const [navFolderStructure, setNavFolderStructure] = useState<any[]>([]);

  return (
    <AppContext.Provider
      value={{
        openNav,
        setOpenNav,
        currentFolder,
        setCurrentFolder,
        breadcrumbs,
        setBreadcrumbs,
        forceFetchFolder,
        setForceFetchFolder,
        isLoadingSave,
        setIsLoadingSave,
        lastDesignStudioLink,
        setLastDesignStudioLink,
        isLoadingDesignStudio,
        setIsLoadingDesignStudio,
        setFolderPath,
        folderPath,
        navFolderStructure,
        setNavFolderStructure,
        openLeftBar,
        setOpenLeftBar,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
