//This component is used as a wrapper for select option in case
//we need it to hide and use toggle logic
import React, { SetStateAction, useEffect, useRef } from "react";
import s from "./SelectOptionWrapper.module.scss";
import SelectOption from "./SelectOption";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface ISelectOptionWrapper {
  options: any;
  openMore: boolean;
  setOpenMore: React.Dispatch<SetStateAction<boolean>>;
  style?: any;
}
const SelectOptionWrapper = ({ options, openMore, setOpenMore, style }: ISelectOptionWrapper) => {
  const selectOptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openMore) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMore]);

  const handleClickOutside = (event: MouseEvent) => {
    if (selectOptionRef.current && !selectOptionRef.current.contains(event.target as Node)) {
      setOpenMore(false);
    }
  };

  return (
    <div className={s.openMore} ref={selectOptionRef}>
      <MoreHorizIcon fontSize="large" onClick={() => setOpenMore(!openMore)} />
      {openMore ? <SelectOption options={options} style={style} /> : null}
    </div>
  );
};

export default SelectOptionWrapper;
