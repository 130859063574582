import React, { useContext, useState } from "react";
import FabricJSContextNew from "../../../../../../core/context/FabricJSContextNew";

import Button from "../../../../../../components/Button/Button";

import { useApiRequest } from "../../../../../../core/api/useApiRequest";
import {
  uniqueID,
  useNotification,
} from "../../../../../../core/context/notifications/NotificationProvider";
import ProjectContext from "../../../../../../core/context/ProjectContext";
import ProductContext from "../../../../../../core/context/ProductContext";
import { convertDataToPostProduct } from "../../../../fabric-functions/POST/ConvertToPostDataProduct";
import addWARect from "../../../../fabric-functions/WorkingAreaLogic/RectWA";
import addWACircle from "../../../../fabric-functions/WorkingAreaLogic/CircleWA";
import saveCanvasJSON from "../../../../fabric-functions/SaveCanvasJSON";
import resetCanvas from "../../../../fabric-functions/ResetCanvas";
import { handleFileInput } from "../../../../fabric-functions/AddToCanvas/HandleFileInput";

const DevMode = () => {
  const { canvasContext, activeGlobalState, setActiveGlobalState } = useContext(FabricJSContextNew);
  const { setProjects } = useContext(ProjectContext);
  const { setProducts } = useContext(ProductContext);

  const addMainObject = () => {};
  const notification = useNotification();
  const { sendRequest } = useApiRequest();
  const saveCanvasState = () => {
    const json = canvasContext.toJSON();
    console.log(json);
  };

  const createToImage = () => {
    const test = canvasContext.toImage();
    console.log(test);
  };
  const handleAddWARect = () => {
    addWARect(canvasContext);
  };

  const handleAddWACircle = () => {
    addWACircle(canvasContext);
  };
  const handleSaveCanvas = () => {
    if (canvasContext) {
      const savedProduct = saveCanvasJSON(canvasContext, "dummy name");
      const formattedJsonString = JSON.stringify(savedProduct, null, 2);
      setJsonOutput(formattedJsonString);
    }
  };

  const [jsonOutput, setJsonOutput] = useState("");
  const [showJSON, setShowJSON] = useState(false);
  const handleConvertedData = () => {
    const readyFormat = convertDataToPostProduct(canvasContext, activeGlobalState);

    const postDataTest = async () => {
      try {
        const response = await sendRequest(
          "post",
          `/creator/2d/templates/${activeGlobalState.fullJSON.id}/batch`,
          readyFormat
        );
        const responsePost = await sendRequest(
          "post",
          `/creator/2d/templates/${activeGlobalState.fullJSON.id}/publish`
        );
        if (responsePost && responsePost.data) {
          const responseData: any = responsePost.data;
          if (responseData) {
            setProducts((prevItems: any) => {
              const itemExists = prevItems.some((item: any) => item.id === responseData.id);

              if (itemExists) {
                return prevItems.map((item: any) =>
                  item.id === responseData.id ? responseData : item
                );
              } else {
                return [...prevItems, responseData];
              }
            });

            setProjects((prevProjects: any) =>
              prevProjects.map((project: any) => ({
                ...project,
                template: project.template.id === responseData.id ? responseData : project.template,
              }))
            );
            setActiveGlobalState((prevState: any) => ({
              ...prevState,
              fullJSON: responseData,
            }));
            notification({
              id: uniqueID,
              type: "SUCCESS",
              message: "Product successfully updated",
            });
          }
          console.log({ response, responsePost });
        }
      } catch (err) {}
    };
    postDataTest();
  };
  return (
    <div>
      <button onClick={addMainObject}>add main object</button>
      <button style={{ marginTop: "30px" }} onClick={handleAddWARect}>
        Create Workable Area
      </button>
      <button style={{ marginTop: "30px" }} onClick={handleAddWACircle}>
        Create Workable Area Circle
      </button>
      <button onClick={handleSaveCanvas}>save working area</button>
      <button onClick={() => resetCanvas(canvasContext)}>Clear canvas</button>
      <button onClick={createToImage}>create to image</button>
      <button onClick={saveCanvasState}>save canvas state</button>
      <Button
        text="choose main image"
        type="input"
        onFileChange={(e) => handleFileInput(1, canvasContext, e, undefined, undefined, undefined)}
      />
      <button onClick={() => setShowJSON(!showJSON)}>show json</button>
      <button onClick={handleConvertedData}>converted data to send</button>

      {showJSON ? (
        <div style={{ display: "flex", overflow: "hidden" }}>
          <pre>{jsonOutput}</pre>
        </div>
      ) : null}
    </div>
  );
};

export default DevMode;
