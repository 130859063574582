import React, { useContext } from "react";
import s from "./Managment.module.scss";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../core/context/AuthContext";
const dD = {
  busniess_plan: "Business",
};
const Managment = () => {
  const navigate = useNavigate();
  const subscriptionHandler = () => {
    navigate("change-subscription");
  };
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <h3>{t("ManagmentComponent.Manage subscription")}</h3>
      <div className={s.inner_container}>
        <div>
          <span>
            <strong>Videlink {user.subscription.name}</strong>
          </span>
          <br />
          <br />
          <span>{t("ManagmentComponent.one")} 12/02/2025</span>
        </div>
        <Button
          type="button"
          text={t("ManagmentComponent.button")}
          handleClick={() => subscriptionHandler()}
        />
      </div>
      <h3>{t("ManagmentComponent.Payment info")}</h3>

      <div className={s.inner_container}>
        <div>
          <span>
            <strong>249,00 PLN / MC {dD?.busniess_plan}</strong>
          </span>
          <br />
          <br />
          <span>
            Następna płatność zaplanowana jest na 16 listopad 2019. Visa
            ****8488
          </span>
        </div>
        <Button
          type="button"
          text="Ustawienia Płatnośći"
          handleClick={() => navigate("payment")}
        />
      </div>

      <div className="solid_line"></div>
      <h3>{t("ManagmentComponent.Cancel subscription")}</h3>
      <div className={s.cancel}>
        <span>{t("ManagmentComponent.two")}</span>
        <a href="">{t("ManagmentComponent.Cancel subscription")}</a>
      </div>
    </div>
  );
};

export default Managment;
