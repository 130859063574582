import React, { useContext, useState } from "react";
import s from "./SelectProduct.module.scss";
import ModalHeader from "../ModalHeader";
import { TextField } from "@mui/material";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../../core/api/useApiRequest";

import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { filterViewToShow } from "../../ProductComponent/filterViewToShow";
import { uuidv4 } from "../../../helper/uuid";
import ProductComponent from "../../ProductComponent/ProductComponent";
import ProjectContext from "../../../core/context/ProjectContext";
import handleLoadCanvasProject from "../../../features/Fabric-JS/fabric-functions/LoadProject";
const buttonStyles = {
  width: "100%",
};
interface ISelectProduct {
  setCloseModal: any;
}
const SelectProductModal = ({ setCloseModal }: ISelectProduct) => {
  const { t } = useTranslation();

  const { sendRequest, isLoading } = useApiRequest();
  const [input, setInput] = useState<string>("");
  const [selectedView, setSelectedView] = useState<any>();
  const { setActiveGlobalState, activeGlobalState, canvasContext, setStyleIsActive } =
    useContext(FabricJSContextNew);
  const { setProjects } = useContext(ProjectContext);

  const selectProductHandler = (selectedView: any, fullJSON: any) => {
    setStyleIsActive(fullJSON.id);
    setSelectedView(selectedView);
    setActiveGlobalState((prevState: any) => ({ ...prevState, selectedView: selectedView }));
  };

  const createNewVersionHandler = async () => {
    try {
      const response = await sendRequest(
        "post",
        `/creator/2d/projects/${activeGlobalState.fullJSON.id}/batch`,
        [
          {
            event: "version_create",
            payload: {
              uuid: uuidv4(),
              view_uuid: activeGlobalState?.selectedView?.uuid,
              name: input,
            },
          },
        ]
      );

      if (response && response.data) {
        const responseData = response.data as any;
        setActiveGlobalState((prevState: any) => {
          const newVersion = responseData?.versions[responseData?.versions?.length - 1];
          const newState = {
            ...prevState,
            selectedView: newVersion,
            fullJSON: {
              ...prevState.fullJSON,
              versions: [...prevState.fullJSON.versions, newVersion],
            },
          };
          // For example:
          const versionToShow = filterViewToShow(
            selectedView.uuid,
            newState.fullJSON,
            activeGlobalState.canvasType
          );

          setStyleIsActive(newVersion.id);
          handleLoadCanvasProject(canvasContext, versionToShow.canvasJSON);
          return newState;
        });
        setProjects((prevState: any) => {
          return prevState.map((el: any) => (el.id === responseData.id ? responseData : el));
        });
        // Handle any additional logic for loading a new version if needed
      }
    } catch (err) {
      console.error(err);
    } finally {
      setCloseModal((prevState: boolean) => !prevState);
    }
  };
  return (
    <div className={s.container}>
      <ModalHeader header={t("SelectProductModal.Header")} />
      <TextField
        name="name"
        label={t("SelectProductModal.Name")}
        onChange={(e) => setInput(e.target.value)}
      />
      <div className={s.list_container}>
        <ProductComponent
          product={activeGlobalState?.fullJSON?.template}
          handleClick={(variant) =>
            selectProductHandler(variant, activeGlobalState?.fullJSON?.template)
          }
          variant="small"
          componentOptions="2"
          id={activeGlobalState?.fullJSON?.template?.id}
        />
      </div>
      <Button
        text={!isLoading ? t("Save") : t("Loading")}
        type="button"
        styles={buttonStyles}
        handleClick={() => createNewVersionHandler()}
        isLoading={isLoading}
        disabled={!selectedView || input.length === 0}
      />
    </div>
  );
};

export default SelectProductModal;
