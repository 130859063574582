import { fabric } from "fabric";
import { debounce } from "lodash";
import takeCanvasSnapshot from "../CreateSnapShotImage";
import { updateCanvasObjectsList } from "../UpdateCanvasObjectList";
import { toJSON } from "../ToJSON";
import updateClipPath from "../UpdateClipPath";

const debouncedTakeCanvasSnapshot = debounce((canvas: fabric.Canvas, setActiveGlobalState: any) => {
  takeCanvasSnapshot(canvas, setActiveGlobalState);
}, 250);

const setupCanvasEventListeners = (
  canvas: fabric.Canvas,
  sortCanvasObjectsByLevel: (canvas: fabric.Canvas) => void,
  setCanvasJSONContext: any,
  setCanvasObjectList: any,
  setActiveGlobalState: any,
  setCanvasActiveObject: any
): (() => void) => {
  canvas.preserveObjectStacking = true;

  const handleObjectMoving = (e: fabric.IEvent): void => {
    syncClonesWithOriginals(canvas);
    updateClipPaths(canvas);
    sortCanvasObjectsByLevel(canvas);
    updateCanvasState();
  };

  const handleObjectModified = (e: fabric.IEvent): void => {
    syncClonesWithOriginals(canvas);
    updateClipPaths(canvas);
    sortCanvasObjectsByLevel(canvas);
    updateCanvasState();
  };

  const handleTextChanged = (e: fabric.IEvent): void => {
    syncClonesWithOriginals(canvas);
    updateClipPaths(canvas);
    sortCanvasObjectsByLevel(canvas);
    updateCanvasState();
  };

  const handleObjectSelection = (e: fabric.IEvent): void => {
    // Check if 'selected' is defined and has at least one object
    if (e && e.selected && e.selected.length > 0) {
      const selectedObject = e.selected[0];

      // Check if 'uuid' exists on the selected object
      if (selectedObject && (selectedObject as any).uuid) {
        const id: any = (selectedObject as any).uuid;
        // console.log("Selected Object UUID:", id);
        updateActiveObjectState(selectedObject);
      } else {
      }
    } else {
    }
  };

  const handleSelectionCleared = (e: any) => {
    setCanvasActiveObject(null); // Clear active object when nothing is selected
    const canvasJSON = toJSON(canvas);
    setCanvasJSONContext(canvasJSON);
    updateCanvasObjectsList(canvas, setCanvasObjectList);
    debouncedTakeCanvasSnapshot(canvas, setActiveGlobalState);
  };

  const updateActiveObjectState = (activeObject: fabric.Object | undefined | null) => {
    if (activeObject) {
      const serializedActiveObject = activeObject.toJSON([
        "id",
        "name",
        "type",
        "opacity",
        "level",
        "selectable",
        "lockMovementX",
        "lockMovementY",
        "lockScalingX",
        "lockScalingY",
        "lockRotation",
        "hasControls",
        "hasBorders",
        "padding",
        "transparentCorners",
        "borderColor",
        "cornerColor",
        "cornerStyle",
        "cornerSize",
        "lockScalingFlip",
        "uuid",
        "guid",
        "isClone",
        "clipPath",
        "order",
      ]);

      setCanvasActiveObject((prevState: any) => ({
        ...prevState,
        objectJSON: serializedActiveObject,
      }));
    } else {
      // No object is selected, clear the active object state
      setCanvasActiveObject(null);
    }

    const canvasJSON = toJSON(canvas);

    debouncedTakeCanvasSnapshot(canvas, setActiveGlobalState);
    setCanvasJSONContext(canvasJSON);
    updateCanvasObjectsList(canvas, setCanvasObjectList);
  };

  const updateCanvasState = () => {
    const canvasJSON = toJSON(canvas);
    setCanvasJSONContext(canvasJSON);
    updateCanvasObjectsList(canvas, setCanvasObjectList);
    debouncedTakeCanvasSnapshot(canvas, setActiveGlobalState);
  };

  const syncClonesWithOriginals = (canvas: fabric.Canvas) => {
    canvas.renderOnAddRemove = false;

    const originals = canvas
      .getObjects()
      .filter((obj) => typeof obj.id === "string" && !obj.id.endsWith("-clone"));
    const clones = canvas
      .getObjects()
      .filter((obj) => typeof obj.id === "string" && obj.id.endsWith("-clone"));

    originals.forEach((original: any) => {
      const cloneId = `${original.id}-clone`;
      const clone = clones.find((o) => o.id === cloneId) as fabric.Object;

      if (clone) {
        clone.set({
          left: original.left,
          top: original.top,
          angle: original.angle,
          scaleX: original.scaleX,
          scaleY: original.scaleY,
          flipX: original.flipX,
          flipY: original.flipY,
        });

        if (original.type === "i-text" || original.type === "text") {
          const textObj = original as fabric.IText;
          const cloneTextObj = clone as fabric.IText;
          cloneTextObj.set({
            text: textObj.text,
            fontSize: textObj.fontSize,
            fontWeight: textObj.fontWeight,
            fontStyle: textObj.fontStyle,
            lineHeight: textObj.lineHeight,
            textAlign: textObj.textAlign,
            fontFamily: textObj.fontFamily,
          });
        }

        clone.setCoords();
      }
    });

    clones.forEach((clone: any) => {
      if (clone.get("level") === 4) {
        const originalId = clone.id.replace("-clone", "");
        const original = originals.find((o: any) => o.id === originalId) as fabric.Object;

        if (original) {
          clone.set({
            left: original.left,
            top: original.top,
            angle: original.angle,
            scaleX: original.scaleX,
            scaleY: original.scaleY,
            flipX: original.flipX,
            flipY: original.flipY,
          });

          if (original.type === "i-text" || original.type === "text") {
            const originalTextObj = original as fabric.IText;
            const cloneTextObj = clone as fabric.IText;
            cloneTextObj.set({
              text: originalTextObj.text,
              fontSize: originalTextObj.fontSize,
              fontWeight: originalTextObj.fontWeight,
              fontStyle: originalTextObj.fontStyle,
              lineHeight: originalTextObj.lineHeight,
              textAlign: originalTextObj.textAlign,
              fontFamily: originalTextObj.fontFamily,
            });
          }

          clone.setCoords();
        }
      }
    });

    canvas.renderAll();
    canvas.renderOnAddRemove = true;
  };

  const updateClipPaths = (canvas: fabric.Canvas) => {
    canvas.forEachObject((obj) => {
      updateClipPath(canvas, obj);
    });
  };

  // Attach event listeners
  canvas.on("object:moving", handleObjectMoving);
  canvas.on("object:modified", handleObjectModified);
  canvas.on("text:changed", handleTextChanged);
  canvas.on("text:modified", handleTextChanged);

  canvas.on("selection:created", handleObjectSelection);
  canvas.on("selection:updated", handleObjectSelection);
  canvas.on("selection:cleared", handleSelectionCleared);

  canvas.on("object:rotating", handleObjectMoving);
  canvas.on("object:scaled", handleObjectMoving);
  canvas.on("object:rotated", handleObjectMoving);

  syncClonesWithOriginals(canvas);
  updateClipPaths(canvas);
  updateCanvasState();

  // Cleanup event listeners on unmount
  return () => {
    canvas.off("object:moving", handleObjectMoving);
    canvas.off("object:modified", handleObjectModified);
    canvas.off("text:changed", handleTextChanged);

    canvas.off("selection:created", handleObjectSelection);
    canvas.off("selection:updated", handleObjectSelection);
    canvas.off("selection:cleared", handleSelectionCleared);

    canvas.off("object:scaling", handleObjectMoving);
    canvas.off("object:rotating", handleObjectMoving);
    canvas.off("object:scaled", handleObjectMoving);
  };
};

export default setupCanvasEventListeners;
