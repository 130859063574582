import React, { ReactNode, useContext } from "react";
import Breadcrumbs from "../components/Nav/Breadcrumbs/Breadcrumbs";
import s from "./Layout.module.scss";
import TopBar from "../components/Nav/TopBar/TopBar";
import MainNav from "../components/Nav/MainNav/MainNav";
import LeftBar from "../components/Nav/LeftBar/LeftBar";
import AppContext from "../core/context/AppContext";
interface ILayout {
  children: ReactNode;
}
const Layout = ({ children }: ILayout) => {
  const { folderPath, setFolderPath } = useContext(AppContext);
  return (
    <div className={s.container}>
      <TopBar />
      <div className={s.children}>
        <MainNav />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%", marginLeft: "10px" }}
        >
          <Breadcrumbs styleOption={1} folderPath={folderPath} setFolderPath={setFolderPath} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
