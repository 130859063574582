import React from "react";
import s from "./LeftIcon.module.scss";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Tooltip from "@mui/material/Tooltip";

interface ILeftIcon {
  toolTipName: string;
  Icon: React.ElementType;
  handleClick: () => void;
}
const LeftIcon = ({ toolTipName, Icon, handleClick }: ILeftIcon) => {
  return (
    <div className={s.container} onClick={() => handleClick()}>
      <Tooltip title={toolTipName} placement="right">
        <Icon />
      </Tooltip>
    </div>
  );
};

export default LeftIcon;
