export const dummyText = {
  type: "text",
  text: [
    {
      text: "dummmy",
      style: {
        fontWeight: "bold",
        fontSize: 43,
        textAlign: "center",
        color: "#e24d71",
        textShadow: "3px 2px 0px #00ff90",
      },
    },
    {
      text: "Loren Epsium",
      style: {
        fontFamily: "Courier New",
        fontWeight: "bold",
        fontSize: 27,
        textAlign: "center",
        color: "#2b6291",
        textShadow: "2px 0px 0px #00ffd4",
      },
    },
  ],
};
