import React, { useContext, useEffect } from "react";
import AuthContext from "./core/context/AuthContext";
import RouterComponent from "./core/router/RouterComponent";
import AppContext from "./core/context/AppContext";
import { useLocation } from "react-router-dom";

function App() {
  const { isLoading, user } = useContext(AuthContext);
  const { lastDesignStudioLink } = useContext(AppContext);
  useEffect(() => {
    if (!isLoading) {
      const currentHostname = window.location.hostname;
      const currentUrl = window.location.href;
      const currentPath = window.location.pathname;
      const currentSubdomain = currentHostname.split(".")[0];

      const allowedPaths = [
        "/login",
        "/forgot-password",
        "/password/reset",
        "/register",
        "/activation",
        "/recover-password",
        "/onboard/payment-plan",
        "/onboard/profile-setup",
        "/onboard/workspace",
        "/public-page",
        "/another-public-page",
        "/design-studio/*",
      ];

      const allowedPathPatterns = [
        /^\/s\/.*/, // Matches all paths starting with /s/
      ];

      const isOnAllowedPath = allowedPaths.some((path) =>
        currentUrl.startsWith(`${window.location.origin}${path}`)
      );

      const isOnAllowedPattern = allowedPathPatterns.some((pattern) => pattern.test(currentPath));

      const loginUrl = `http://app.videlink.com.pl/free-editor`;

      // Redirect unauthenticated users to login page if not on allowed paths/patterns
      if (!user && !isOnAllowedPath && !isOnAllowedPattern) {
        if (currentUrl !== loginUrl) {
          window.location.href = loginUrl;
        }
        return;
      }

      // Redirect authenticated users to their workspace subdomain
      if (user && user.workspace && user.workspace.slug) {
        const correctSubdomainUrl = `http://${user.workspace.slug}.videlink.com.pl/design-studio`;
        if (!isOnAllowedPath && !isOnAllowedPattern && currentSubdomain !== user.workspace.slug) {
          window.location.href = correctSubdomainUrl;
        }
      }

      // Ensure that unauthenticated users are on the 'app' subdomain
      if (!user && currentSubdomain !== "app") {
        window.location.href = loginUrl;
      }
    }
  }, [isLoading, user]);

  return (
    <div className="App">
      {/* {isLoading ? (
        <LoadingState styles={loadingStyle100} text={t("Loading")} />
      ) : (
      )} */}
      <RouterComponent />
    </div>
  );
}

export default App;
