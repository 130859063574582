import React, { useContext } from "react";
import s from "../FabricJS.module.scss";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import SingleComponent from "../../../../../components/SingleComponent/SingleComponent";
import { filterVersionToShow } from "../../../../../helper/filterVersionToShow";
import { filterViewToShow } from "../../../../../components/ProductComponent/filterViewToShow";
import AddViewButton from "../../../../../components/AddViewButton/AddViewButton";
import handleLoadCanvasProject from "../../../fabric-functions/LoadProject";
import { updateCanvasObjectsList } from "../../../fabric-functions/UpdateCanvasObjectList";

const RightPanel = () => {
  const { canvasContext, activeGlobalState, setActiveGlobalState, setCanvasObjectList } =
    useContext(FabricJSContextNew);
  //Selects active view from the views
  const selectVersion = (data: any) => {
    if (activeGlobalState.selectedView.uuid === data.uuid) {
      return;
    }
    setActiveGlobalState((prevState: any) => {
      const newState = {
        ...prevState,
        selectedView: data,
      };
      //guard so that state wont reload if someone clicks the same view
      const projectToShow = filterVersionToShow(
        newState.selectedView?.view_uuid,
        newState.selectedView?.uuid,
        newState.fullJSON,
        true
      );
      handleLoadCanvasProject(canvasContext, projectToShow.canvasJSON);
      return newState;
    });
  };

  const selectView = (data: any) => {
    if (activeGlobalState.selectedView.uuid === data.uuid) {
      return;
    }
    setActiveGlobalState((prevState: any) => {
      const newState = {
        ...prevState,
        selectedView: data,
      };

      const productToShow = filterViewToShow(
        newState.selectedView?.uuid,
        newState.fullJSON,
        activeGlobalState.canvasType
      );

      handleLoadCanvasProject(canvasContext, productToShow.canvasJSON);
      updateCanvasObjectsList(canvasContext, setCanvasObjectList);

      return newState;
    });
  };
  return (
    <div className={s.views}>
      {activeGlobalState?.canvasType === "product" &&
        activeGlobalState?.fullJSON &&
        activeGlobalState?.fullJSON?.views?.map((el: any, i: number) => (
          <div key={`fabric-js-map-pages-${i}`}>
            <SingleComponent
              key={`fabric-js-map-pages-${i}`}
              variant="small"
              handleClick={(selectedViewToShow) => selectView(selectedViewToShow)}
              data={el}
            />
          </div>
        ))}
      {activeGlobalState?.canvasType === "product" ? (
        <div>
          <AddViewButton />
        </div>
      ) : null}

      {activeGlobalState?.canvasType === "project" &&
        activeGlobalState?.fullJSON &&
        activeGlobalState?.fullJSON?.versions?.map((el: any, i: number) => (
          <div key={`fabric-js-map-pages-${i}`}>
            <SingleComponent
              key={`fabric-js-map-pages-${i}`}
              variant="small"
              handleClick={(selectedViewToShow) => selectVersion(selectedViewToShow)}
              data={el}
            />
          </div>
        ))}
      {activeGlobalState?.canvasType === "project" ? (
        <div>
          <AddViewButton />
        </div>
      ) : null}
    </div>
  );
};

export default RightPanel;
