import React from "react";
import s from "./ImportFile.module.scss";
import Button from "../../../Button/Button";
import googleDrive from "./assets/googleDrive.svg";
import fb from "./assets/fb.svg";
import ig from "./assets/ig.svg";

const ImportFile = () => {
  return (
    <>
      <div className={s.container}>
        <span>
          ZAŁADUJ OBRAZ (NP. PRODUKTU) Z KTÓRYM ROZPOCZNIESZ WIZUALIZACJĘ
        </span>
        <span>Przenieś i upuść obraz w ten obszar lub</span>
        <Button text="WYBIERZ PLIK Z DYSKU" type="input" />
        <span>
          Maksymalny rozmiar pliku to 100 mb.
          <br /> Akceptowalne formaty: .jpg, .png, .tiff, .svg.
        </span>
      </div>
      <div className={s.icons}>
        <span>Lub prześlij plik z:</span>
        <div>
          <img src={googleDrive} alt={googleDrive} />
          <span>Google drive</span>
        </div>
        <div>
          <img src={fb} alt={fb} />
          <span>Facebook</span>
        </div>
        <div>
          <img src={ig} alt={ig} />
          <span>Instagram</span>
        </div>
      </div>
    </>
  );
};

export default ImportFile;
