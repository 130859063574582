import React from "react";
import s from "./Modal.wrapper.module.scss";
import CloseIcon from "@mui/icons-material/Close";
interface IModalWrapper {
  children: React.ReactNode;
  setOpen: (arg: boolean) => void;
}

const ModalWrapper = ({ children, setOpen }: IModalWrapper) => {
  return (
    <div className={s.container}>
      <div>
        <CloseIcon onClick={() => setOpen(false)} />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ModalWrapper;
