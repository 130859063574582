import { fabric } from "fabric";
import { extendObject, extendTextInfo } from "../ExtendObject";
import createFollowerText from "./createFollowers/CreateFollowerText";
import updateClipPath from "../UpdateClipPath";
import sortCanvasObjectsByLevel from "../SortCanvasObjectsByLevel";
import { updateCanvasObjectsList } from "../UpdateCanvasObjectList";
import { uuidv4 } from "../../../../helper/uuid";

const getMaxOrder = (canvas: any, level: number) => {
  let maxOrder = 0;
  canvas.getObjects().forEach((obj: any) => {
    if (obj.level === level && obj.order > maxOrder) {
      maxOrder = obj.order;
    }
  });
  return maxOrder;
};

const addText = async (canvas: any, textData: any, setCanvasObjects: any) => {
  if (canvas && textData.text) {
    const textObj: any = new fabric.IText(textData.text, {
      fontSize: textData.fontSize,
      fill: textData.color,
      originX: "center",
      originY: "center",
    });
    console.log(textData, "addTEXT");

    // Cast the text object to the custom type
    const extendedTextObject = textObj as fabric.IText & any;

    const maxOrder = getMaxOrder(canvas, textData.level);
    const extendedText = extendTextInfo(extendedTextObject, { ...textData, order: maxOrder + 1 });

    extendedText.level = textData.level;
    extendedText.id = `${Date.now().toString()}-text`;
    extendedText.opacity = 1;
    extendedText.uuid = uuidv4();

    if (textData.level !== 1) {
      const cloneSettings = {
        id: `${extendedText.id}-clone`,
        level: 3,
      };

      //create follower for illusion
      const clonedText = await createFollowerText(textObj, cloneSettings);
      canvas.add(clonedText);
    }

    canvas.add(extendedText);
    updateClipPath(canvas, extendedText);
    sortCanvasObjectsByLevel(canvas);
    updateCanvasObjectsList(canvas, setCanvasObjects);
    canvas.renderAll();
  }
};

export default addText;
