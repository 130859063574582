import { convertPdfToSvg } from "../ConvertPDFToSVG";
import resetCanvas from "../ResetCanvas";
import { convertPDFToPNG } from "../convertToPng";
import addGateway from "./AddGateway";

export const handleFileInput = async (
  level: number,
  canvas: any,
  setCanvasObjectList: any,
  e?: React.ChangeEvent<HTMLInputElement>,
  imageUrl?: string,
  closeModal?: () => void
) => {
  if (imageUrl) {
    console.log(imageUrl, "FILE INPUT");
    const addObject = {
      url: imageUrl,
      name: "",
      level,
      fileType: "img",
    };
    addGateway(canvas, addObject, setCanvasObjectList);

    if (closeModal) closeModal();
    return;
  }
  const file = e?.target.files?.[0];
  console.log(file);
  if (!file) {
    if (closeModal) closeModal();
    return;
  }
  if (file.type === "application/pdf") {
    try {
      const url = await convertPDFToPNG(file);

      const addObject = {
        url,
        name: file.name,
        level,
        fileType: "img",
      };

      addGateway(canvas, addObject, setCanvasObjectList);

      if (closeModal) closeModal();
    } catch (error) {
      console.error("Error converting PDF to PNG:", error);
    }
  } else {
    const fileTypeMapping: { [key: string]: string } = {
      "application/pdf": "svg",
    };

    const fileType = fileTypeMapping[file.type] ?? (file.type.startsWith("image/") ? "img" : "svg");
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      // setAddedImages([e.target?.result as string]);

      const addObject = {
        url: e?.target?.result as string,
        name: file.name,
        level,
        fileType,
      };

      addGateway(canvas, addObject, setCanvasObjectList);
    };

    reader.readAsDataURL(file);

    if (closeModal) closeModal();
  }
};
