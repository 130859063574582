import React from "react";
import s from "./GraphicComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
interface IGraphicComponent {
  variant: string;
  handleClick: () => void;
  id: string;
  imgSrc: string;
}
const GraphicComponent = ({ handleClick, variant, imgSrc, id }: IGraphicComponent) => {
  return (
    <div
      className={`${s.container} ${
        variant === "2ex_small"
          ? s.two_ex_small
          : variant === "ex_small"
          ? s.ex_small
          : variant === "small"
          ? s.small
          : variant === "medium"
          ? s.medium
          : s.large
      }  `}
      onClick={() => handleClick()}
    >
      <ComponentBackground>
        <div
          className={`${s.img_container} ${
            variant === "2ex_small"
              ? s.two_ex_small
              : variant === "ex_small"
              ? s.ex_small
              : variant === "small"
              ? s.small
              : variant === "medium"
              ? s.medium
              : s.large
          }  `}
        >
          <img src={imgSrc} alt={id} />
        </div>
      </ComponentBackground>
      {/* <div className={s.info}>
        <span>{product.title}</span>
        <span>{product.size}</span>
      </div> */}
    </div>
  );
};

export default GraphicComponent;
