import React, { useContext, useEffect, useState } from "react";
import s from "./ProductsDashboard.module.scss";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import ProductComponent from "../../components/ProductComponent/ProductComponent";
import FabricJSContextNew from "../../core/context/FabricJSContextNew";
import { useNavigate } from "react-router-dom";
import AddIconButton from "../../components/AddIconButton/AddIconButton";
import { useTranslation } from "react-i18next";
import NormalModal from "../../components/Modals/Modal/NormalModal";
import ModalWrapper from "../../components/Modals/Modal.wrapper";
import CreateFolder from "../../components/Modals/FolderModals/CreateFolder/CreateFolder";
import AppContext from "../../core/context/AppContext";
import CreateProduct from "../../components/Modals/CreateProduct/CreateProduct";
import SlideUpModal from "../../components/Modals/Modal/SildeUpModal";
import FilterComponent from "../../components/FilterComponent/FilterComponent";
import handleLoadCanvasProject from "../Fabric-JS/fabric-functions/LoadProject";

import FolderNodeTree from "../../components/FolderComponents/FolderNodeThree/FolderNodeTree";

const ProductsDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { folderPath, setFolderPath } = useContext(AppContext);
  const { canvasContext, setActiveGlobalState } = useContext(FabricJSContextNew);

  const [openCreateFolder, setOpenCreateFolder] = useState<boolean>(false);
  const [openCreateProduct, setOpenCreateProduct] = useState<boolean>(false);

  const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);

  const projectHandler = (selectedView: any, wholeProduct: any) => {
    handleLoadCanvasProject(canvasContext, selectedView);
    setActiveGlobalState({ canvasType: "product", fullJSON: wholeProduct });
    navigate(`/design-studio/product/${wholeProduct?.token}`);
  };

  useEffect(() => {
    if (folderPath.length > 0) {
      const lastFolderInArray: number = folderPath[folderPath.length - 1]?.id;

      setCurrentFolderId(lastFolderInArray);
    } else {
      setCurrentFolderId(null);
    }
  }, [folderPath]);

  return (
    <>
      <div className={s.container}>
        <h3>{t("Products")}</h3>

        <h4>{t("LastUsed")}</h4>
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/creator/2d/templates"
            defaultSort="id"
            defaultPagination={5}
            isPagination={false}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProductComponent
                    key={`dashboard-product-products-recent-map${i}`}
                    product={el}
                    variant="medium"
                    handleClick={(variant) => projectHandler(variant, el)}
                    componentOptions="1"
                    id={`dashboard-product-products-recent-map${i}`}
                  />
                ))}
              </div>
            )}
          />
        </div>

        <h4>{t("All")}</h4>
        <div className={s.folder_div}>
          <FolderNodeTree
            requestPath="templates"
            routerPath="products"
            folderPath={folderPath}
            setFolderPath={setFolderPath}
          />
        </div>
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/creator/2d/templates"
            defaultSort="id"
            defaultPagination={10}
            isPagination={true}
            category_id={currentFolderId?.toString()}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProductComponent
                    key={`dashboard-product-products-all-map${i}`}
                    product={el}
                    variant="medium"
                    handleClick={(variant) => projectHandler(variant, el)}
                    componentOptions="1"
                    id={`dashboard-product-products-all-map${i}`}
                  />
                ))}
              </div>
            )}
          />
        </div>
        <div className={s.button_div}>
          <AddIconButton
            handleClick={() => setOpenCreateProduct(true)}
            Icon={NoteAddIcon}
            tooltip={t("New product")}
          />
          <AddIconButton
            handleClick={() => setOpenCreateFolder(true)}
            Icon={CreateNewFolderIcon}
            tooltip={t("New folder")}
          />
        </div>
      </div>

      <SlideUpModal open={openCreateProduct} onClose={() => setOpenCreateProduct(false)}>
        <CreateProduct />
      </SlideUpModal>
      <NormalModal open={openCreateFolder} setOpen={setOpenCreateFolder}>
        <ModalWrapper setOpen={setOpenCreateFolder}>
          <CreateFolder
            setCloseModal={setOpenCreateFolder}
            parent_id={currentFolderId || 0}
            postCreateFolderPath="templates"
          />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default ProductsDashboard;
