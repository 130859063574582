import React, { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import imgOne from "../../../assets/images/slides/01.png";
import imgTwo from "../../../assets/images/slides/02.png";
import imgThree from "../../../assets/images/slides/03.png";
import imgFour from "../../../assets/images/slides/04.png";
import imgFive from "../../../assets/images/slides/05.png";
import imgSix from "../../../assets/images/slides/06.png";
import imgSeven from "../../../assets/images/slides/07.png";

const imgs = [imgOne, imgTwo, imgThree, imgFour, imgFive, imgSix, imgSeven];

const AuthSwiper = React.memo(() => {
  const swiperConfig = useMemo(
    () => ({
      centeredSlides: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      modules: [Autoplay, EffectFade],
      className: "mySwiper",
      // effect: "fade",
    }),
    []
  );

  return (
    <Swiper {...swiperConfig}>
      {imgs?.map((el, i) => (
        <SwiperSlide key={`Login-swiper-${i}`}>
          <img src={el} alt={`img${i}`} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
});

export default AuthSwiper;
