import React, { useContext, useState } from "react";

import s from "./TextDashboard.module.scss";

import TextComponent from "../../components/TextComponent/TextComponent";
import FilterComponent from "../../components/FilterComponent/FilterComponent";
import { useTranslation } from "react-i18next";

const TextDashboard = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={s.container}>
        <h3>{t("Text")}</h3>

        <h4>{t("LastUsed")}</h4>
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/media/text"
            defaultSort="id"
            defaultPagination={5}
            isPagination={false}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <TextComponent
                    key={`texts-text-last-map${i}`}
                    variant="small"
                    id={`texts-text-last-map${i}`}
                    textInfo={el}
                    handleClick={() => {}}
                  />
                ))}
              </div>
            )}
          />
        </div>

        <h4>{t("All")}</h4>
        <div className={s.folder_div}>
          {/* {folderMap?.map((el, i) => (
            <Folder
              key={`dashboard-projects-folders-map${i}`}
              text={el}
              handleClick={() => {}}
            />
          ))} */}
        </div>
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/media/text"
            defaultSort="id"
            defaultPagination={5}
            isPagination={true}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <TextComponent
                    key={`texts-text-all-map${i}`}
                    variant="small"
                    id={`texts-text-all-map${i}`}
                    textInfo={el}
                    handleClick={() => {}}
                  />
                ))}
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default TextDashboard;
