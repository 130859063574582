import { createContext, ReactNode, useState, useEffect } from "react";

import Cookies from "js-cookie";
import { useGetRequest } from "../api/useGetRequest";
import { useNavigate } from "react-router-dom";

// interface IUser {
//   isLoggedIn: boolean;
//   id: number;
//   access: number;
//   biz: boolean;
//   hostname: string;
// }

interface AuthContextValue {
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  isLoading: boolean;
  setRegistrationSteps: any;
  registrationStep: any;
  setReloadFlagAuth: any;
  setForceFetch: any;
}

const AuthContext = createContext<AuthContextValue>({
  user: {},
  setUser: () => {},
  isLoading: true,
  setRegistrationSteps: () => {},
  registrationStep: {},
  setReloadFlagAuth: false,
  setForceFetch: () => {},
});

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [forceFetch, setForceFetch] = useState<boolean>(false);
  const [registrationStep, setRegistrationSteps] = useState({});
  const [workspace, setWorkspace] = useState<any>();
  const [reloadFlagAuth, setReloadFlagAuth] = useState(false);
  // console.log(registrationStep);

  const { getData } = useGetRequest();
  const fetchUser = async () => {
    setIsLoading(true);

    const response = await getData("/user");
    if (response.status === 200) {
      setUser(response.data.data);
      setIsLoading(false);
    }
  };

  const accessToken = Cookies.get("vl_access_token");
  const refreshToken = Cookies.get("vl_refresh_token");

  // console.log({ accessToken, refreshToken });
  useEffect(() => {
    if (accessToken && !user) {
      fetchUser();
    } else {
      setIsLoading(false);
    }
  }, [user, accessToken, forceFetch]);

  const redirect = () => {
    const currentLocation = window.location.hostname;
    const lastPath = window.location.href.split("/")[3] || "";
    const currentSlug = currentLocation.split(".")[0];

    if (user) {
      if (user?.workspace?.slug !== currentSlug) {
        const newUrl = `http://${user.workspace.slug}.videlink.com.pl/${lastPath}`;
        window.location.href = newUrl;
      }
    }
  };
  // useEffect(() => {
  //   console.log(user, "USER, AUTH CONTEXT");
  //   // redirect();
  // }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isLoading,
        setRegistrationSteps,
        registrationStep,
        setReloadFlagAuth,
        setForceFetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
