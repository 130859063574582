import { useState, useCallback } from "react";
import apiClient from "./axios-client";
import { useNotification, uniqueID } from "../context/notifications/NotificationProvider";

// Define a type for the HTTP method to ensure only valid methods are used
type HttpMethod = "get" | "post" | "put" | "delete" | "options" | "head";

// Optionally, you can define interfaces for the response and error if you have a standard format
interface ApiResponse<T> {
  data: T;
  status: number;
  url: string;
  // Include other response properties as needed
}

interface ApiError {
  message: string;
  // Include other error properties as needed
}

// Function to format nested error messages
const formatErrors = (errors: any): string => {
  if (typeof errors === "string") return errors;
  if (Array.isArray(errors)) return errors.map(formatErrors).join(", ");
  if (typeof errors === "object" && errors !== null) {
    return Object.values(errors).map(formatErrors).join(" ");
  }
  return String(errors);
};

export const useApiRequest = <T,>() => {
  const notification = useNotification();
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | null>(null);

  const sendRequest = useCallback(
    async (type: HttpMethod, path: string, requestData?: any): Promise<ApiResponse<T> | null> => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await apiClient[type](path, requestData);
        setData(response.data);

        return response.data;
      } catch (err: any) {
        let errorMessage = err.message;
        if (err.response) {
          const errorResponseData = err.response.data;
          let mainErrorMessage = "";
          if (errorResponseData.message) {
            mainErrorMessage = errorResponseData.message;
          } else if (errorResponseData.data?.message) {
            mainErrorMessage = errorResponseData.data.message;
          }

          // Format additional error details without keys
          const additionalErrors = formatErrors(errorResponseData)
            .replace(mainErrorMessage, "")
            .trim();
          if (additionalErrors) {
            errorMessage = `${mainErrorMessage} ${additionalErrors}`;
          } else {
            errorMessage = mainErrorMessage;
          }
        }

        const errorObj = { message: errorMessage };
        setError(errorObj);
        notification({
          id: uniqueID(),
          type: "ERROR",
          message: errorMessage,
        });
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [notification]
  );

  return { data, isLoading, error, sendRequest };
};
