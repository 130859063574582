import React, { useContext, useEffect, useState } from "react";
import s from "./Profile.module.scss";
import Edit from "../../../components/Edit/Edit";
import uuid from "react-uuid";
import NormalModal from "../../../components/Modals/Modal/NormalModal";
import ModalWrapper from "../../../components/Modals/Modal.wrapper";
import EditProfile from "../../../components/Modals/Settings/EditProfile/EditProfile";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../core/context/AuthContext";
import EditPassword from "../../../components/Modals/Settings/EditPassword/EditPassword";
import EditAddressInfo from "../../../components/Modals/Settings/EditAddressInfo/EditAddressInfo";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [editProfileModal, setEditProfileModal] = useState<boolean>(false);
  const [editAddressModal, setEditAddressModal] = useState<boolean>(false);
  const [editPasswordModal, setEditPasswordModal] = useState<boolean>(false);

  const { user } = useContext(AuthContext);

  const helperProfileData = [t("User name"), t("Address email"), t("Preferred language")];

  const helperAddressData = [t("Name"), t("Address"), t("City"), t("Zip code"), t("Tax number")];

  const [profileData, setProfileData] = useState([
    { label: helperProfileData[0], value: user?.name || "" },
    { label: helperProfileData[1], value: user?.email || "" },
    { label: helperProfileData[2], value: currentLanguage.toUpperCase() || "" },
  ]);

  const [addressData, setAddressData] = useState([
    {
      label: helperAddressData[0],
      value: user?.workspace?.billing_name || "",
    },
    {
      label: helperAddressData[1],
      value: user?.workspace?.billing_street || "",
    },
    {
      label: helperAddressData[2],
      value: user?.workspace?.billing_city || "",
    },
    {
      label: helperAddressData[3],
      value: user?.workspace?.billing_postcode || "",
    },
    { label: helperAddressData[4], value: user?.workspace?.billing_tin || "" },
  ]);

  useEffect(() => {
    setProfileData([
      { label: helperProfileData[0], value: user?.name || "" },
      {
        label: helperProfileData[1],
        value: user?.email || "",
      },
      {
        label: helperProfileData[2],
        value: currentLanguage.toUpperCase() || "",
      },
    ]);

    setAddressData([
      {
        label: helperAddressData[0],
        value: user?.workspace?.billing_name || "",
      },
      {
        label: helperAddressData[1],
        value: user?.workspace?.billing_street || "",
      },
      {
        label: helperAddressData[2],
        value: user?.workspace?.billing_city || "",
      },
      {
        label: helperAddressData[3],
        value: user?.workspace?.billing_postcode || "",
      },
      {
        label: helperAddressData[4],
        value: user?.workspace?.billing_tin || "",
      },
    ]);
  }, [user]);

  return (
    <>
      <div className={s.container}>
        <h3>{t("Profile")}</h3>
        <div className={s.top}>
          <div>
            <img src={user?.media?.avatar} alt="avatar" />
            <div className={s.table}>
              {profileData.map((item: any) => (
                <div key={uuid()} className={s.row}>
                  <span>{item.label}</span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </div>
          <Edit handleClick={() => setEditProfileModal((prev) => !prev)} />
        </div>
        <h3>{t("Address information")}</h3>
        <div className={s.middle}>
          <div className={s.table}>
            {addressData.map((item: any) => (
              <div key={uuid()} className={s.row}>
                <span>{item.label}</span>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
          <Edit handleClick={() => setEditAddressModal((prev) => !prev)} />
        </div>
        <h3>{t("Security")}</h3>
        <div className={s.middle}>
          <div className={s.row}>
            <span>{t("Password")}</span>
            <span>*******</span>
          </div>
          <Edit handleClick={() => setEditPasswordModal((prev) => !prev)} />
        </div>
        <h3>{t("Delete account")}</h3>
        <div className={s.bottom}>
          <span>Usunięcie konta jest nieodwracalne, zostaną usunięte Twoje wszelkie dane.</span>
          <span>{t("Delete account")}</span>
        </div>
      </div>

      <NormalModal open={editProfileModal} setOpen={setEditProfileModal}>
        <ModalWrapper setOpen={setEditProfileModal}>
          <EditProfile setCloseModal={setEditProfileModal} />
        </ModalWrapper>
      </NormalModal>

      <NormalModal open={editAddressModal} setOpen={setEditAddressModal}>
        <ModalWrapper setOpen={setEditAddressModal}>
          <EditAddressInfo setCloseModal={setEditAddressModal} />
        </ModalWrapper>
      </NormalModal>

      <NormalModal open={editPasswordModal} setOpen={setEditPasswordModal}>
        <ModalWrapper setOpen={setEditPasswordModal}>
          <EditPassword />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default Profile;
