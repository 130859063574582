import { fabric } from "fabric";

const updateCanvasObjectsList = (
  canvas: fabric.Canvas,
  setCanvasObjectList: (objects: fabric.Object[]) => void
): void => {
  if (canvas) {
    const allObjects = canvas.getObjects();

    // Filter objects based on specific criteria
    const objects = allObjects.filter((obj: any) => {
      const id = typeof obj.id === "string" ? obj.id : "";
      const isClone = id.endsWith("-clone");
      const name = typeof obj.name === "string" ? obj.name : "";

      return (
        ((id.includes("-img") || id.includes("-text")) && !isClone) ||
        name.includes("workingArea") ||
        obj.level === 3 // Include level 3 objects
      );
    });

    setCanvasObjectList(objects);
  }
};

const reorderCanvasObjects = (canvas: fabric.Canvas, objects: any[]): void => {
  if (canvas) {
    const activeObject = canvas.getActiveObject();
    canvas.discardActiveObject();
    const level1Objects = objects.filter((obj) => obj.level === 1);
    const level4Objects = objects.filter((obj) => obj.level === 4);
    const otherObjects = objects.filter((obj) => obj.level !== 1 && obj.level !== 4);
    const levl3Objects = objects.filter((obj) => obj.level === 3);

    const reorderedLevel4And3 = level4Objects.reduce((acc: any[], obj) => {
      acc.push(obj);
      const followers = objects.filter(
        (follower) =>
          follower.level === 3 && follower.parentId === obj.id && follower.id.endsWith("-clone")
      );
      acc.push(...followers);
      return acc;
    }, []);

    // Update the `order` property for each level group
    level1Objects.forEach((obj, index) => {
      obj.order = index + 1;
    });

    reorderedLevel4And3.forEach((obj, index) => {
      obj.order = index + 1;
    });

    canvas.getObjects().forEach((obj) => canvas.remove(obj));

    // Add objects back to the canvas in the new order
    [...level1Objects, ...otherObjects, ...reorderedLevel4And3].forEach((obj) => {
      canvas.add(obj);
    });
    console.log({ level1Objects, otherObjects, reorderedLevel4And3 });
    // Clear and update the internal objects array with the new order
    objects.length = 0;
    [...level1Objects, ...otherObjects, ...reorderedLevel4And3].forEach((obj) => {
      objects.push(obj);
    });

    if (activeObject) {
      canvas.setActiveObject(activeObject);
    }
    canvas.renderAll(); // Update the canvas to reflect the active object
  }
};

export { updateCanvasObjectsList, reorderCanvasObjects };
