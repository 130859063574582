import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { loadingStyle100 } from "../../helper/customeStyles";
import LoadingState from "../../components/LoadingState/LoadingState";
import { useTranslation } from "react-i18next";

interface IProtectedRoute {
  children?: React.ReactNode;
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({ children }) => {
  const redirectPath = "/login";

  const { t } = useTranslation();
  
  const { user, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <LoadingState styles={loadingStyle100} text={t("Loading")} />;
  }

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
