import React from "react";

import DCWrapper from "../../components/DashboardContainerWrapper/DCWrapper";

import Add from "./Add/Add";
import ProductList from "./ProductList/ProductList";
import ProjectList from "./ProjectList/ProjectList";

const Dashboard = () => {
  return (
    <DCWrapper>
      <Add />
      <ProductList />
      <ProjectList />
    </DCWrapper>
  );
};

export default Dashboard;
