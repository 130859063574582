import React from "react";
import s from "./TextComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";

interface ITextComponent {
  textInfo: any;
  variant: string;
  handleClick: () => void;
  id: string;
}

const TextComponent = ({ textInfo, handleClick, variant, id }: ITextComponent) => {
  console.log(textInfo, "textInfo");

  // Extract styles from the textInfo JSON object and map them to valid CSS properties
  const textStyle: React.CSSProperties = {
    color: textInfo?.json?.fill,
    fontSize: `${textInfo?.json?.fontSize}px`,
    fontFamily: textInfo?.json?.fontFamily,
    fontWeight: textInfo?.json?.fontWeight,
    fontStyle: textInfo?.json?.fontStyle,
    // textAlign: textInfo?.json?.textAlign,
    textAlign: "center",
    lineHeight: textInfo?.json?.lineHeight,
    textDecoration: `${textInfo?.json?.underline ? "underline" : ""} ${
      textInfo?.json?.overline ? "overline" : ""
    } ${textInfo?.json?.linethrough ? "line-through" : ""}`,
    opacity: textInfo?.json?.opacity,
    textTransform: textInfo?.json?.textBackgroundColor ? "uppercase" : "none", // Example of additional style logic
  };

  return (
    <div className={s.container} onClick={() => handleClick()}>
      <ComponentBackground>
        <div
          className={`${s.img_container} ${
            variant === "small" ? s.small : variant === "medium" ? s.medium : s.large
          }`}
        >
          <p style={textStyle}>{textInfo?.json?.text}</p>
        </div>
      </ComponentBackground>
    </div>
  );
};

export default TextComponent;
