import { fabric } from "fabric";

type TextProps = {
  text?: string;
  fontSize?: number;
  fontWeight?: string;
  fontStyle?: string;
  textAlign?: "left" | "center" | "right" | "justify";
  fill?: string;
  underline?: boolean;
  background?: any;
};

const changeText = (canvas: fabric.Canvas, newTextProps: any) => {
  const activeObject = canvas.getActiveObject() as any;

  if (activeObject && activeObject.type === "i-text") {
    Object.keys(newTextProps).forEach((prop) => {
      const key = prop as keyof TextProps;
      activeObject.set(key, newTextProps[key]);
    });

    const cloneId = `${activeObject.id}-clone`;
    const clone = canvas.getObjects().find((o) => o.id === cloneId) as any;

    if (clone) {
      Object.keys(newTextProps).forEach((prop) => {
        const key = prop as keyof TextProps;
        clone.set(key, newTextProps[key]);
      });
    }

    canvas.renderAll();
  }
};

export default changeText;
