import { pdfjs } from "react-pdf";

export const convertPDFToPNG = async (file: File): Promise<string> => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return new Promise(async (resolve, reject) => {
    try {
      const fileData = new Blob([file], { type: "application/pdf" });
      const url = URL.createObjectURL(fileData);

      const pdfDocument = await pdfjs.getDocument(url).promise;

      const page = await pdfDocument.getPage(1);
      const viewport = page.getViewport({ scale: 0.5 });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (!context) {
        throw new Error("Canvas context not available");
      }

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: context, viewport }).promise;

      const pngUrl = canvas.toDataURL("image/png");
      resolve(pngUrl);

      URL.revokeObjectURL(url);
    } catch (error) {
      reject(error);
    }
  });
};
