export const convertDataToPostProduct = (canvasContext: any, activeGlobalState: any) => {
  console.log(activeGlobalState, "ACTIVE STATE FUNCTION");

  if (canvasContext) {
    // Custom serialization to exclude clipPath from all objects on the canvas
    const customToJSON = canvasContext.toJSON.bind(canvasContext);

    canvasContext.toJSON = (propertiesToInclude: any) =>
      customToJSON(propertiesToInclude).objects.map((obj: any) => {
        const { clipPath, ...rest } = obj; // Destructure to exclude clipPath
        return rest;
      });

    const canvasJson = canvasContext.toJSON([
      "id",
      "name",
      "type",
      "opacity",
      "level",
      "selectable",
      "lockMovementX",
      "lockMovementY",
      "lockScalingX",
      "lockScalingY",
      "lockRotation",
      "hasControls",
      "guid",
      "uuid",
      "isClone",
      "order",
    ]);

    // Resetting toJSON to original function to avoid side effects
    canvasContext.toJSON = customToJSON;

    // Map UUIDs to IDs from activeGlobalState.fullJSON.layers for quick lookup and ID assignment
    const layerIdMap = new Map();

    activeGlobalState?.fullJSON?.layers?.forEach((layer: any) => {
      layerIdMap.set(layer.uuid, layer.id);
    });

    const filteredCanvasJson = canvasJson.filter((obj: any) => !obj.isClone && obj.level !== 5);

    // Prepare new or updated layers
    const readyFormat = filteredCanvasJson.map((obj: any) => {
      const isLayerExist = layerIdMap.has(obj.guid || obj.uuid);

      const eventName = isLayerExist ? "layer_update" : "layer_create";

      console.log(obj);
      return {
        event: eventName,
        payload: {
          id: obj.id,
          uuid: obj.guid || obj.uuid,
          ...(activeGlobalState.canvasType === "product"
            ? { view_uuid: activeGlobalState?.selectedView?.uuid }
            : {}),
          ...(activeGlobalState.canvasType === "project"
            ? { version_uuid: activeGlobalState?.selectedView?.uuid }
            : {}),
          is_locked: false,
          is_visible: false,
          order: obj.order || 0,
          name: obj.name || "",
          type: "text",
          json: obj,
          variation_uuid: activeGlobalState.fullJSON.variations[0].uuid || "fake",
        },
      };
    });

    // Prepare deleted layers
    const canvasUUIDs = new Set(filteredCanvasJson.map((obj: any) => obj.guid || obj.uuid));
    const deletedLayers = activeGlobalState.fullJSON.layers
      .filter(
        (layer: any) =>
          !canvasUUIDs.has(layer.uuid) &&
          (activeGlobalState.canvasType === "product"
            ? layer.view_uuid === activeGlobalState?.selectedView?.uuid
            : layer.version_uuid === activeGlobalState?.selectedView?.uuid)
      )
      .map((layer: any) => ({
        event: "layer_delete",
        payload: {
          id: layer.id,
          uuid: layer.uuid,
          ...(activeGlobalState.canvasType === "product"
            ? { view_uuid: activeGlobalState?.selectedView?.uuid }
            : {}),
          ...(activeGlobalState.canvasType === "project"
            ? { version_uuid: activeGlobalState?.selectedView?.uuid }
            : {}),
          is_locked: false,
          is_visible: false,
          order: layer.order || 0,
          name: layer.name || "",
          type: "text",
          json: layer,
          variation_uuid: activeGlobalState.fullJSON.variations[0].uuid || "fake",
        },
      }));

    // Prepare overprint working area
    const workingAreaObj = filteredCanvasJson.find((obj: any) => obj.id === "workingArea");
    let overprintEvent = null;
    if (workingAreaObj) {
      const isWorkingAreaExist = layerIdMap.has(workingAreaObj.guid || workingAreaObj.uuid);
      const overprintEventName = isWorkingAreaExist ? "overprint_update" : "overprint_create";
      overprintEvent = {
        event: overprintEventName,
        payload: {
          type: null,
          uuid: workingAreaObj.guid || workingAreaObj.uuid,
          variation_uuid: "any",
          ...(activeGlobalState.canvasType === "product"
            ? { view_uuid: activeGlobalState?.selectedView?.uuid }
            : {}),
          ...(activeGlobalState.canvasType === "project"
            ? { version_uuid: activeGlobalState?.selectedView?.uuid }
            : {}),
          json: workingAreaObj,
        },
      };
    }

    const thumbnailRegenerateEvent = {
      event: "thumbnail_regenerate",
      payload: activeGlobalState.generateThumbnail,
    };

    const viewUpdateEvent = {
      event: "view_update",
      payload: {
        thumbnail: activeGlobalState.generateThumbnail,
        uuid: activeGlobalState?.selectedView?.uuid,
        name: activeGlobalState?.selectedView?.name,
      },
    };

    // Combine all events
    const result = [thumbnailRegenerateEvent, viewUpdateEvent, ...readyFormat, ...deletedLayers];
    if (overprintEvent) {
      result.push(overprintEvent);
    }

    console.log(result);
    return result;
  }
};
