import React, { useContext, useState } from "react";
import s from "./TopBar.module.scss";
import AuthContext from "../../../core/context/AuthContext";
import AppContext from "../../../core/context/AppContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import SelectOption from "../../SelectOption/SelectOption";
import { replace } from "lodash";
import Cookies from "js-cookie";
import DesignStudioTopBar from "./components/DesignStudioTopBar";

const dummyData = {
  name: "Daniel",
  company: "Videlink",
  img: "/profile.webp",
  notification: "3",
};
const TopBar = () => {
  const appCtx = useContext(AppContext);
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showProfileSettings, setShowProfileSettings] = useState<boolean>(false);

  const showProfileSettingsHandler = () => {
    setShowProfileSettings(!showProfileSettings);
  };

  const logout = () => {
    const currentDomain = `${user.workspace.slug}.videlink.com.pl`;
    console.log(currentDomain);
    Cookies.remove("vl_access_token", { domain: ".videlink.com.pl", path: "/" });
    Cookies.remove("vl_refresh_token", { domain: ".videlink.com.pl", path: "/" });
    Cookies.remove("vl_access_token");
    Cookies.remove("vl_refresh_token");
    setUser(null);
    navigate("/login");
  };

  const profileSettingsNav = [
    {
      link: "/settings",
      name: "Panel Administracyjny",
    },
    {
      link: "/settings",
      name: "Edytuj Konto",
    },
    {
      name: "Wyloguj",
      function: logout,
    },
  ];
  const paths = ["/design-studio", "free-editor"];
  const designStudioPaths = paths.some((path) => location.pathname.includes(path));

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.left}>
          <Link to={"/dashboard"}>
            <h1>Videlink</h1>{" "}
          </Link>
          <div
            onClick={() => appCtx.setOpenNav(!appCtx.openNav)}
            id="nav-icon2"
            className={!appCtx.openNav ? "open" : ""}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={s.right}>
          {!designStudioPaths && user ? ( // Only show this if not in design studio path and user is logged in
            <div className={s.info}>
              <span>{user?.name},</span>
              <span>@{user?.workspace?.name}</span>
            </div>
          ) : (
            <DesignStudioTopBar /> // Show design studio top bar only when in design studio path
          )}
          {user ? (
            <div className={s.img_container} onClick={() => showProfileSettingsHandler()}>
              <div className={s.notification}>
                <span>{dummyData.notification}</span>
              </div>
              <img src={user?.media?.avatar} alt="profile picture" />
              <div className={`${s.profile_settings} ${showProfileSettings ? s.show : null}`}>
                <SelectOption options={profileSettingsNav} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className={s.style}></div> */}
    </div>
  );
};

export default TopBar;
