import React from "react";
import s from "./Create.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
interface ICreate {
  title: string;
  image: string;
  handleClick: () => void;
}
const Create = ({ title, image, handleClick }: ICreate) => {
  return (
    <ComponentBackground>
      <div className={s.container} onClick={() => handleClick()}>
        <h3>{title}</h3>
        <div>
          <img src={image} alt={image} />
        </div>
      </div>
    </ComponentBackground>
  );
};

export default Create;
