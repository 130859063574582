import React from "react";
import { TNavigationItem } from "../../../features/Settings/types";
import { iconMap } from "../../../features/Settings/navigation";
import s from "./MiniNav.module.scss";
import Biz from "../../Biz/Biz";
import uuid from "react-uuid";
interface IMiniNav {
  navigation: TNavigationItem[];
  action: string;
  setAction: (arg: string) => void;
}

const MiniNav = ({ navigation, action, setAction }: IMiniNav) => {
  return (
    <div className={s.container}>
      {navigation.map((item) => (
        <div
          onClick={() => setAction(item?.link)}
          className={s.nav_item}
          key={uuid()}
        >
          {React.createElement(iconMap[item.ava])}
          <span>{item.name}</span>
          {item.premium && <Biz />}
        </div>
      ))}
    </div>
  );
};

export default MiniNav;
