import React, { useState } from "react";
import s from "./RecoverPassword.module.scss";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import { Link } from "react-router-dom";
import { validateEmail } from "../../../helper/validation";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import { useApiRequest } from "../../../core/api/useApiRequest";

const buttonStyles = { width: "100%" };

const RecoverPassword = () => {
  const [input, setInput] = useState<{ email: string }>({ email: "" });
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  const { t } = useTranslation();
  const notification = useNotification();
  const { sendRequest, isLoading } = useApiRequest();

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });

    // Validate email on change
    if (name === "email") {
      setIsEmailValid(validateEmail(value));
    }
  };

  const handleResetForgottenPass = async () => {
    if (!isEmailValid) return;

    const responseData: any = await sendRequest("post", "auth/password/email", input);
    if (responseData) {
      notification({ type: "SUCCESS", id: uniqueID(), message: t("Auth.RecoveryLinkSent") });
      setInput({ email: "" });
      setIsEmailValid(false); // Reset email validation state
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <h2>VIDELINK</h2>
          <span className={s.slogan}>{t("Forgot your password?")}</span>

          <TextField
            name="email"
            onChange={inputHandler}
            value={input.email}
            className={s.input}
            id="outlined-basic"
            autoComplete="off"
            label={t("Address email")}
            variant="outlined"
            color="primary"
            error={!isEmailValid && input.email !== ""}
            onBlur={() => setIsEmailValid(validateEmail(input.email))}
          />

          <Button
            text={isLoading ? t("Loading") : t("Recover password")}
            type="button"
            handleClick={handleResetForgottenPass}
            styles={buttonStyles}
            isLoading={isLoading}
            disabled={!isEmailValid || isLoading}
          />
          <Link to="/login">{t("Back to login")}</Link>
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default RecoverPassword;
