import React, { useState } from "react";
import s from "./PaymentPlan.module.scss";
import ComponentBackground from "../../../../components/ComponentBackground/ComponentBackground";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "../../../../components/Button/Button";
interface IPaymentPlan {
  // el: {
  //   type: string;
  //   period: string;
  //   description: string;
  //   perks: [string];
  //   price: string;
  // };
  el: any;
  backgroundStyles?: React.CSSProperties;
  buttonStyles?: any;
  handleClick: any;
}

const PaymentPlan = ({ el, backgroundStyles, buttonStyles, handleClick }: IPaymentPlan) => {
  const [active, setActive] = useState<boolean>(false);
  const [age, setAge] = useState<string>("");
  return (
    <ComponentBackground styles={backgroundStyles}>
      <div className={s.container}>
        <div className={s.header}>
          <span>VIDELINK</span>
          <span>{el?.type}</span>
          <span>{el?.period}</span>
        </div>

        <p>{el?.description}</p>

        <div className={`${s.perk_contanier} ${active ? s.active : ""}`}>
          {el?.perks?.map((el: any) => (
            <span>{el}</span>
          ))}
        </div>

        <div className={s.show_more} onClick={() => setActive(!active)}>
          <KeyboardArrowDownIcon className={active ? s.active : ""} />
          <span>pokaz więcej</span>
        </div>
        <h5>Przestrzeń dyskowa</h5>
        <div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" style={{ textAlign: "center" }}>
              GB
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="GB"
              size="small"
              // onChange={handleChange}
            >
              <MenuItem value={10}>10GB</MenuItem>
              <MenuItem value={20}>20GB</MenuItem>
              <MenuItem value={30}>50GB</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={s.price}>
          <span>{el?.price}</span>
          <span>PLN</span>
          <span>+VAT</span>
        </div>
        <Button
          text={buttonStyles?.text}
          type="button"
          styles={buttonStyles?.styles}
          handleClick={() => handleClick()}
        />
      </div>
    </ComponentBackground>
  );
};

export default PaymentPlan;
