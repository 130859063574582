import DashboardIcon from "@mui/icons-material/Dashboard";
import CreateIcon from "@mui/icons-material/Create";
import CategoryIcon from "@mui/icons-material/Category";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import SettingsIcon from "@mui/icons-material/Settings";
import ComputerIcon from "@mui/icons-material/Computer";
import DescriptionIcon from "@mui/icons-material/Description";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import DeleteIcon from "@mui/icons-material/Delete";
import { TNavigationItem, TIconMap } from "./type";

export const navigationMenu = (curentProjectLink: string): TNavigationItem[] => [
  // {
  //   name: "Dashboard",
  //   link: "/dashboard",
  //   isLink: true,
  //   ava: "dashboard",
  // },
  {
    name: "Quick menu",
    isLink: false,
    children: [
      {
        name: "Design",
        link: `/${curentProjectLink}`,
        isLink: true,
        ava: "design",
      },
    ],
  },
  {
    name: "Library",
    isLink: false,
    children: [
      {
        name: "Products",
        link: "/products",
        biz: true,
        isLink: true,
        ava: "products",
        hasFolderStructure: true,
      },
      {
        name: "Projects",
        link: "/projects",
        isLink: true,
        ava: "projects",
        hasFolderStructure: true,
      },
      {
        name: "Media (files)",
        link: "/media",
        isLink: true,
        ava: "media",
      },
      {
        name: "Text",
        link: "/text",
        isLink: true,
        ava: "text",
      },
      {
        name: "Bin",
        link: "/bin",
        isLink: true,
        ava: "bin",
      },
    ],
  },
  {
    name: "My site",
    isLink: false,
    children: [
      {
        name: "Manage site",
        link: "/manage",
        isLink: true,
        ava: "manage",
      },
    ],
  },
  {
    name: "My account",
    isLink: false,
    children: [
      {
        name: "Notifications",
        link: "/notifications",
        isLink: true,
        ava: "notifications",
      },
      {
        name: "Settings",
        link: "/settings/profile",
        isLink: true,
        ava: "options",
      },
      {
        name: "Subscription",
        link: "/subscription/managment",
        isLink: true,
        ava: "subscriptions",
      },
    ],
  },
];

export const iconMap: any = {
  dashboard: DashboardIcon,
  design: CreateIcon,
  products: PermMediaIcon,
  projects: CreateNewFolderIcon,
  media: CategoryIcon,
  text: TextFieldsIcon,
  bin: DeleteIcon,
  manage: ComputerIcon,
  notifications: AnnouncementIcon,
  options: SettingsIcon,
  subscriptions: DescriptionIcon,
};
