import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./core/locales/en/en.json";
import translationPL from "./core/locales/pl/pl.json";
import translationDE from "./core/locales/de/de.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
  de: {
    translation: translationDE,
  },
};
i18n
  .use(LanguageDetector) // Detect language settings
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    fallbackLng: "en", // Use 'en' if detected lng is unavailable
    debug: true, // Set to false in production
    resources,
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },

    // This path might need to be adjusted depending on your server setup
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
