import React from "react";
import s from "./LoadingState.module.scss";

interface ILoadingState {
  text: string;
  styles: React.CSSProperties;
}
const LoadingState = ({ text, styles }: any) => {
  return (
    <div style={styles} className={s.loader}>
      <span>{text}</span>
    </div>
  );
};

export default LoadingState;
