import React, { useContext } from "react";
import s from "./ComponentBackground.module.scss";
import FabricJSContextNew from "../../core/context/FabricJSContextNew";

interface IComponentBackground {
  children: React.ReactNode;
  styles?: React.CSSProperties;
  id?: any;
}

const ComponentBackground: React.FC<IComponentBackground> = ({ children, styles, id }) => {
  const { styleIsActive, activeGlobalState } = useContext(FabricJSContextNew);
  const isActive = id === styleIsActive;
  // || id === activeGlobalState?.fullJSON?.id;
  return (
    <div style={styles} className={`${s.container}${isActive ? ` ${s.active}` : ""}`}>
      {children}
    </div>
  );
};

export default ComponentBackground;
