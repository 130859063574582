import React, { useState, useEffect } from "react";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TextField } from "@mui/material";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { useTranslation } from "react-i18next";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";

interface IFolder {
  id: number;
  name: string;
  parent_id: number;
  children?: [];
}

interface INavFolderTreeStructure {
  requestPath: string;
  setSelectedFolder?: any;
  selectedFolder?: any;
  title: string;
}

const NavFolderTreeStructure = ({
  requestPath,
  setSelectedFolder,
  selectedFolder,
  title,
}: INavFolderTreeStructure) => {
  const { sendRequest } = useApiRequest();
  const [treeData, setTreeData] = useState<TreeViewBaseItem[]>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [selectedNodeLabel, setSelectedNodeLabel] = useState<string>("");
  const [selectedNode, setSelectedNode] = useState<TreeViewBaseItem | null>(null);
  const { t } = useTranslation();

  const fetchFolders = async () => {
    try {
      const response: any = await sendRequest("get", `creator/2d/${requestPath}/categories`);
      const data: IFolder[] = response.data;

      const treeItems = buildTreeData(data);
      setTreeData(treeItems);
      setSelectedNode(treeItems[0]);
      setSelectedNodeLabel(treeItems[0].label as string); // Ensure label is cast to string
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  const buildTreeData = (items: IFolder[]): TreeViewBaseItem[] => {
    const idToNodeMap: { [key: number]: TreeViewBaseItem } = {};

    // Initialize nodes and map them by their IDs
    items.forEach((item) => {
      idToNodeMap[item.id] = {
        id: item.id.toString(),
        label: item.name, // Keep the label as a string
        children: [],
      };
    });

    const roots: TreeViewBaseItem[] = [];

    // Build the tree by assigning children to their parents
    items.forEach((item) => {
      const node = idToNodeMap[item.id];
      if (item.parent_id === 0) {
        // Root node
        roots.push(node);
      } else {
        const parent = idToNodeMap[item.parent_id];
        if (parent) {
          parent.children!.push(node);
        } else {
          console.warn(`Parent with ID ${item.parent_id} not found for item ${item.id}`);
        }
      }
    });

    // Add "Root" folder at the top of the tree
    const rootFolder: TreeViewBaseItem = {
      id: "0",
      label: "Root", // Label is still a string here
      children: roots,
    };

    return [rootFolder]; // Return the new tree with "Root" at the top
  };

  const getItemId = (item: TreeViewBaseItem) => {
    return item.id;
  };

  const handleSelectedItemsChange = (event: React.SyntheticEvent, itemId: string | null) => {
    setSelectedNodeId(itemId);

    if (itemId == null) {
      setSelectedNode(null);
      setSelectedNodeLabel("");
      setSelectedFolder(0);
    } else {
      const findNode = (nodes: TreeViewBaseItem[]): TreeViewBaseItem | null => {
        for (let node of nodes) {
          if (node.id === itemId) {
            return node;
          }
          if (node.children && node.children.length > 0) {
            const foundNode = findNode(node.children);
            if (foundNode) return foundNode;
          }
        }
        return null;
      };

      const node = findNode(treeData);
      setSelectedNode(node);
      setSelectedFolder(node?.id);
      setSelectedNodeLabel(node?.label || "");
    }
  };

  return (
    <>
      <div className="container-folder-tree-structure-nav">
        <h4>{t(title)}</h4>
        <RichTreeView
          items={treeData}
          selectedItems={selectedNodeId}
          onSelectedItemsChange={handleSelectedItemsChange}
          multiSelect={false}
          getItemId={getItemId}
        />
      </div>
    </>
  );
};

export default NavFolderTreeStructure;
