export const filterVersionToShowPreview = (viewUuid: string, versionUuid: string, product: any) => {
  // Process layers from product.layers that match the versionUuid
  const productLayersJson =
    product.layers
      ?.filter((layer: any) => layer.version_uuid === versionUuid)
      .map((layer: any) => layer.json) ?? [];

  let layersJson = [...productLayersJson];
  console.log({ layersJson }, "1");

  // If product.template exists, process template layers that match the viewUuid
  if (product.template) {
    const templateLayersJson =
      product.template.layers
        ?.filter((layer: any) => layer.view_uuid === viewUuid)
        .map((layer: any) => ({
          ...layer.json,
          selectable: false,
          evented: false,
          hasControls: false,
          hoverCursor: "default",
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockSkewingX: true,
          lockSkewingY: true,
          editable: false,
          moveCursor: "default",
          lockScalingFlip: true,
          hasBorders: false,
        })) ?? [];
    layersJson = [...layersJson, ...templateLayersJson];
  }

  // Process layers to disable movement and editing for all objects
  const updatedLayersJson = layersJson.map((obj: any) => {
    return {
      ...obj,
      selectable: false,
      evented: false,
      hasControls: false,
      hoverCursor: "default",
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      lockSkewingX: true,
      lockSkewingY: true,
      editable: false,
      moveCursor: "default",
      lockScalingFlip: true,
      hasBorders: false,
    };
  });

  const canvasJSON = { version: "5.3.0", objects: updatedLayersJson };
  return { viewUuid, canvasJSON };
};
