import React from "react";
import { LeftBarImageI } from "../../../../types/generalTypes";

const Products = ({ isActive, isLoggedIn }: LeftBarImageI) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27.996" viewBox="0 0 28 27.996">
      <g id="Product_Library" data-name="Product Library" transform="translate(19.002 54)">
        <path
          id="Path_4523"
          data-name="Path 4523"
          d="M28,22.747V6.3h0V4.316a1.05,1.05,0,0,0-1.05-1.05H17.964V1.05A1.05,1.05,0,0,0,16.914,0H3.266a1.05,1.05,0,0,0-1.05,1.05V3.266H1.05A1.05,1.05,0,0,0,0,4.316v1.75H0v20.88A1.05,1.05,0,0,0,1.05,28H26.829a1.05,1.05,0,0,0,1.05-1.05V23.33A1.05,1.05,0,0,0,28,22.747ZM4.316,2.1H15.864V3.15H4.316ZM8.165,16.8H6.649V10.848l2.8-.7c.467,1.633,1.866,2.333,4.433,2.333s4.083-.817,4.433-2.45l2.8.7v5.949H19.83a1.166,1.166,0,0,0-1.166,1.166v7.815H9.332v-7.7A1.166,1.166,0,0,0,8.165,16.8ZM25.779,6.066h0V25.9H21V19.13h1.4a1.166,1.166,0,0,0,1.166-1.166V9.915a1.166,1.166,0,0,0-.933-1.166L17.5,7.582a1.166,1.166,0,0,0-1.4,1.283h0c0,.583,0,1.05-2.216,1.05s-2.216-.117-2.216-.7V8.982a1.166,1.166,0,0,0-1.4-1.283L5.133,8.865A1.166,1.166,0,0,0,4.2,10.032v8.049a1.166,1.166,0,0,0,1.166,1.166H7V25.9H2.1V6.3h0V5.366h23.68Z"
          transform="translate(-19.002 -54)"
          fill={isLoggedIn ? "#808d98" : !isActive ? "#ffff" : "#808d98"}
        />
      </g>
    </svg>
  );
};

export default Products;
