import React from "react";
import s from "./Edit.module.scss";
import EditIcon from "@mui/icons-material/Edit";
interface IEdit {
  handleClick: () => void;
}
const Edit = ({ handleClick }: IEdit) => {
  return (
    <div className={s.container} onClick={() => handleClick()}>
      <EditIcon fontSize="small" />
      <span>Edytuj</span>
    </div>
  );
};

export default Edit;
