import { fabric } from "fabric";

const undoStack: string[] = [];
const redoStack: string[] = [];

export const saveState = (canvas: fabric.Canvas | null) => {
  if (canvas) {
    const jsonData = canvas.toJSON();
    undoStack.push(JSON.stringify(jsonData));
    redoStack.length = 0;
  }
};

export const undo = (canvas: fabric.Canvas | null) => {
  if (undoStack.length > 0 && canvas) {
    const currentState = JSON.stringify(canvas.toJSON());
    const previousState = undoStack.pop();
    if (previousState) {
      redoStack.push(currentState);
      canvas.loadFromJSON(previousState, () => {
        canvas.renderAll();
      });
    }
  }
};

export const redo = (canvas: fabric.Canvas | null) => {
  if (redoStack.length > 0 && canvas) {
    const nextState = redoStack.pop();
    if (nextState) {
      undoStack.push(JSON.stringify(canvas.toJSON()));
      canvas.loadFromJSON(nextState, () => {
        canvas.renderAll();
      });
    }
  } else {
  }
};
