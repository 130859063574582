import React, { useContext, useEffect } from "react";
import WorkShopProject from "./components/WorkShopProject/WorkShopProject";
import s from "./FabricJS.module.scss";
import TopTools from "./components/TopTools/TopTools.wrapper";
import FabricJSContextNew from "../../../../core/context/FabricJSContextNew";
import { fabric } from "fabric";

import WorkShopProduct from "./components/WorkShopProduct/WorkShopProduct";
import RightPanel from "./panels/RightPanel";

import { filterViewToShow } from "../../../../components/ProductComponent/filterViewToShow";
import { filterVersionToShow } from "../../../../helper/filterVersionToShow";

import { useLocation } from "react-router-dom";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import AppContext from "../../../../core/context/AppContext";
import setupCanvasEventListeners from "../../fabric-functions/EventListiners/EventListiners";
import sortCanvasObjectsByLevel from "../../fabric-functions/SortCanvasObjectsByLevel";
import handleLoadCanvasProject, { centerCanvasAtOrigin } from "../../fabric-functions/LoadProject";
import { updateCanvasObjectsList } from "../../fabric-functions/UpdateCanvasObjectList";
import addGateway, {
  IFileInformation,
  ITextInformation,
} from "../../fabric-functions/AddToCanvas/AddGateway";
declare module "fabric" {
  namespace fabric {
    interface Object {
      id?: string | number;
      level: number | string;
    }
  }
  interface CanvasObject {
    id: string | number;
    type: string | undefined;
    left: number | undefined;
    top: number | undefined;
    width: number | undefined;
    height: number | undefined;
  }
}

const FabricJSNew = () => {
  const [isCanvasReady, setIsCanvasReady] = React.useState(false);

  const {
    canvasContext,
    canvasRefs,
    canvasContainerRefs,
    setCanvasContext,
    setCanvasObjectList,
    setCanvasJSONContext,
    canvasJSONContext,
    activeGlobalState,
    setCanvasSize,
    canvasSize,
    setCanvasActiveObject,
    setActiveGlobalState,
    setCreatingNewWork,
    tempActiveGlobalState,

    creatingNewWork,
  } = useContext(FabricJSContextNew);
  const { setIsLoadingDesignStudio, isLoadingDesignStudio } = useContext(AppContext);
  const { sendRequest } = useApiRequest();

  useEffect(() => {
    if (canvasContainerRefs[0].current) {
      if (!canvasContext) {
        const newCanvas = new fabric.Canvas(canvasRefs[0].current);
        setCanvasContext(newCanvas);
        setCanvasJSONContext(newCanvas.toJSON());

        // Set the initial center of the canvas to (0,0)
        setIsCanvasReady(true); // Canvas is ready
      } else {
        if (canvasContainerRefs[0]) {
          canvasContainerRefs[0].current.innerHTML = "";
          canvasContainerRefs[0].current.appendChild(canvasContext.lowerCanvasEl);
          canvasContainerRefs[0].current.appendChild(canvasContext.upperCanvasEl);

          canvasContext.renderAll();
          setIsCanvasReady(true); // Canvas is ready
        }
      }
    }
  }, [canvasContext, setCanvasContext, canvasContainerRefs[0]]);

  useEffect(() => {
    if (canvasContext) {
      const cleanup = setupCanvasEventListeners(
        canvasContext,
        sortCanvasObjectsByLevel,
        setCanvasJSONContext,
        setCanvasObjectList,
        setActiveGlobalState,
        setCanvasActiveObject
      );

      centerCanvasAtOrigin(canvasContext);
      updateCanvasObjectsList(canvasContext, setCanvasObjectList);
      // sortCanvasObjectsByLevel(canvasContext);

      return () => {
        cleanup();
      };
    }
  }, [canvasContext]);

  useEffect(() => {
    if (canvasContext) {
      canvasContext.setDimensions({
        width: canvasSize?.width - 1,
        height: canvasSize?.height - 1,
      });

      centerCanvasAtOrigin(canvasContext);
    }
  }, [canvasSize]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect) {
        setCanvasSize({
          width: entries[0].contentRect.width - 1,
          height: entries[0].contentRect.height - 1,
        });
      }
    });
    if (canvasContainerRefs[0].current) {
      resizeObserver.observe(canvasContainerRefs[0].current);
    }
    return () => {
      if (canvasContainerRefs[0].current) {
        resizeObserver.unobserve(canvasContainerRefs[0].current);
      }
    };
  }, [canvasContext, setCanvasSize]);

  const location = useLocation();
  const path = location.pathname;
  const locationType = path.split("/")[2];
  const requestToken = path.split("/")[3];
  const canvasType = locationType === "product" ? "templates" : "projects";

  const fetchDesignStudioData = async () => {
    const response: any = await sendRequest(
      "get",
      `/creator/2d/${canvasType}?token=${requestToken}`
    );
    if (response) {
      const fileInfo = {
        level: 1,
        fileType: "img",
        url: creatingNewWork.newWorkImageUrl,
        name: "",
      };

      if (canvasType === "templates") {
        setActiveGlobalState((prevState: any) => ({
          ...prevState,
          fullJSON: response?.data,
          selectedView: response?.data?.views[0],
        }));

        const productToShow = filterViewToShow(
          response?.data?.views[0]?.uuid,
          response?.data,
          "product"
        );

        handleLoadCanvasProject(canvasContext, productToShow.canvasJSON, setCanvasObjectList);
        if (creatingNewWork.new) {
          isNewWorkCreated(fileInfo);
        }
      } else if (canvasType === "projects") {
        if (creatingNewWork.new) {
          console.log("THIS IS RUNNINg");
          const jsonToShow = filterViewToShow(
            activeGlobalState.selectedView.view_uuid,
            response.data,
            "product",
            true //isNewProject
          );
          handleLoadCanvasProject(canvasContext, jsonToShow.canvasJSON, setCanvasObjectList);

          isNewWorkCreated(fileInfo);
        } else {
          const productToShow = filterVersionToShow(
            response?.data?.versions[0]?.view_uuid,
            response?.data?.versions[0]?.uuid,
            response?.data,
            true
          );
          setActiveGlobalState({
            canvasType: "project",
            fullJSON: response?.data,
            selectedView: response?.data?.versions[0],
          });

          handleLoadCanvasProject(canvasContext, productToShow.canvasJSON, setCanvasObjectList);
        }
      }
    }
  };
  const isNewWorkCreated = (fileInfo: IFileInformation | ITextInformation) => {
    addGateway(canvasContext, fileInfo, setCanvasObjectList);
    setCreatingNewWork((prevState: any) => ({ ...prevState, new: false }));
  };

  useEffect(() => {
    if (
      location.pathname === "/design-studio" ||
      location.pathname === "/design-studio/" ||
      !isCanvasReady
    )
      return;

    // Ensure the canvas is fully ready before fetching data and updating the object list
    if (canvasContext && isCanvasReady) {
      fetchDesignStudioData();

      // Update the object list after ensuring the canvas is ready and objects are loaded
      updateCanvasObjectsList(canvasContext, setCanvasObjectList);
    }
  }, [isCanvasReady, location.pathname]);

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (canvasContext) {
        canvasContext.clear(); // Clear all objects from the canvas

        setCanvasContext(null); // Optionally reset the canvas context state
      }
    };
  }, [canvasContext, setCanvasContext]);

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div
        style={{
          width: "99.5%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 80px)",
        }}
      >
        <TopTools />
        <div
          ref={canvasContainerRefs[0]}
          className={s.canvas_container}
          style={{
            width: "100%",
            display: "flex",
            height: "100%",
            position: "relative",
          }}
        >
          <canvas ref={canvasRefs[0]} id="main-fabric-working-studio" />
        </div>
      </div>
      <RightPanel />
      {activeGlobalState.canvasType === "product" ? (
        <WorkShopProduct />
      ) : activeGlobalState.canvasType === "project" ? (
        <WorkShopProject />
      ) : null}
    </div>
  );
};

export default FabricJSNew;
