import React, { useEffect, useState } from "react";
import Text from "./TextLeftBar/Text";
import s from "./RenderAction.module.scss";

import GraphicsLeftBar from "./GraphicsLeftBar/GraphicsLeftBar";
import ProductsLeftBar from "./ProductsLeftBar/ProductsLeftBar";
import ProjectsLeftBar from "./ProjectsLeftBar/ProjectsLeftBar";

interface RenderActionsProps {
  action: string;
  slideDirection: string;
  activeItem: null | string;
  open: boolean;
  setActiveItem: (arg: string) => void;
  setOpen: (arg: boolean) => void;
}

const RenderAction = ({ action, slideDirection, open }: RenderActionsProps) => {
  const [shouldHide, setShouldHide] = useState(false);

  const actionCondition = action !== null;
  const actions: Record<string, JSX.Element> = {
    graphics: <GraphicsLeftBar />,
    text: <Text />,
    products: <ProductsLeftBar />,
    projects: <ProjectsLeftBar />,
  };

  useEffect(() => {
    let timer: any;
    if (open) {
      setShouldHide(false);
    } else {
      timer = setTimeout(() => {
        setShouldHide(true);
      }, 150);
    }

    return () => clearTimeout(timer);
  }, [open]);

  return actionCondition ? (
    <div className={`${s.sliding_container} ${s[slideDirection]} ${shouldHide ? s.hide : ""}`}>
      {actions[action] || null}
    </div>
  ) : null;
};

export default RenderAction;
