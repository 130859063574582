import React, { useContext, useEffect, useState } from "react";
import Graphics from "./Graphics/TopToolsGraphics";
import Text from "./Text/TopToolsText";
import NoElement from "./NoElement/NoElement";
import FabricJSContextNew from "../../../../../../core/context/FabricJSContextNew";
import LoadingState from "../../../../../../components/LoadingState/LoadingState";
import { useTranslation } from "react-i18next";
interface IRenderAction {
  isLoading?: boolean;
}
const RenderAction = ({ isLoading }: IRenderAction) => {
  const [action, setAction] = useState("noElement");
  const { canvasActiveObject } = useContext(FabricJSContextNew);
  const { t } = useTranslation();
  useEffect(() => {
    // Assuming canvasActiveObject is updated only on user interaction
    if (canvasActiveObject && canvasActiveObject?.objectJSON) {
      const typeOfElement = canvasActiveObject?.objectJSON?.id?.split("-")[1];

      if (canvasActiveObject.objectJSON.id === "workingarea") {
        setAction("workingArea");
      } else if (typeOfElement) {
        setAction(typeOfElement);
      } else {
        setAction("noElement");
      }
    } else {
      setAction("noElement");
    }

    return () => {
      setAction("noElement");
    };
  }, [canvasActiveObject]);

  const actions: Record<string, JSX.Element> = {
    img: <Graphics />,
    text: <Text />,
    workingarea: <NoElement />,
    noElement: <NoElement />,
  };
  if (isLoading) {
    return <LoadingState text={t("Saving")} />;
  }
  return <>{actions[action]}</>;
};

export default RenderAction;
