import React from "react";
import s from "./TopOfModal.module.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
interface ITopOfModal {
  header: string;
  link: string;
}
const TopOfModal = ({ link, header }: ITopOfModal) => {
  return (
    <>
      <h3 className="modal-header-h3">{header}</h3>
      <div className="link-container-modal">
        <span>{link}</span>
      </div>
      <div className={s.icon_container}>
        <a href="">Kopiuj link</a>
        <FacebookIcon />
        <InstagramIcon />
      </div>
      <div className={`${s.solid_line} solid-line`}></div>
    </>
  );
};

export default TopOfModal;
