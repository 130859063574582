import createFollowerImage from "./createFollowers/CreateFollowerImage";
import createFollowerObject from "./createFollowers/CreateFollowerObject";
import { extendImageObject, extendSVGObject } from "../ExtendObject";
import sortCanvasObjectsByLevel from "../SortCanvasObjectsByLevel";
import { updateCanvasObjectsList } from "../UpdateCanvasObjectList";
import { fabric } from "fabric";

const addObject = (canvas: any, imageInfo: any, setCanvasObjects: any) => {
  if (!canvas || !imageInfo) return;

  if (imageInfo.fileType === "svg") {
    fabric.loadSVGFromURL(imageInfo.imageUrl, async (objects, options) => {
      const svg: any = fabric.util.groupSVGElements(objects, options);
      svg.set({
        left: 0,
        top: 0,
      });
      const extendedSvg = extendSVGObject(svg, imageInfo); // Assuming extendImageObject can handle SVG objects

      // Clone handling for SVG, similar to image cloning
      if (imageInfo.level === 4) {
        const cloneSettings = {
          id: `${extendedSvg.id}-clone`,
          name: `${extendedSvg.name}-clone`,
          level: 3,
        };
        // Assuming createFollowerImage can handle SVG objects
        const clonedSvg = await createFollowerObject(svg, cloneSettings);
        canvas.add(clonedSvg);
      }
      console.log(extendedSvg);
      canvas.add(extendedSvg);
      sortCanvasObjectsByLevel(canvas);

      updateCanvasObjectsList(canvas, setCanvasObjects);
    });
  }
};
export default addObject;
