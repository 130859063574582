import React, { useContext, useEffect, useState } from "react";
import s from "./OnboardPaymentPlan.module.scss";
import PaymentPlan from "../../Subscribtions/Managment/components/PaymentPlan";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { dummyPaymentPlans } from "../../../dummy-data/paymentPlans";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { useNavigate } from "react-router-dom";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import AuthContext from "../../../core/context/AuthContext";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { IPlan } from "../../../components/Nav/MainNav/type";

const paymentStyles = {
  margin: "auto",
};

const OnboardPaymentPlan = () => {
  const { setRegistrationSteps } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setForceFetch } = useContext(AuthContext);
  const [plans, setPlans] = useState<[]>([]);
  const [activePlan, setActivePlan] = useState<{
    index: number;
    disabled: boolean;
    styles: React.CSSProperties;
    text: string;
  }>({
    index: 0,
    disabled: false,
    styles: { margin: "auto" },
    text: t("Currently selected"),
  });

  const { sendRequest, isLoading } = useApiRequest();
  const handleButtonClick = (index: number) => {
    setActivePlan({
      index,
      disabled: true,
      styles: { background: "gray", color: "white", margin: "auto" },
      text: t("Currently selected"),
    });
  };

  const getButtonStyles = (index: number) => {
    const styles = {
      background: index === activePlan.index ? "gray" : "",
      color: index === activePlan.index ? "white" : "",
      width: index === activePlan.index ? "220px" : "220px",

      margin: "auto",
    };

    const text = index === activePlan.index ? activePlan.text : t("Select");
    const disabled = index === activePlan.index;

    return {
      styles,
      text,
      disabled,
    };
  };
  const fetchPaymentPlan = async () => {
    const { data }: any = await sendRequest("get", "/plans");

    setPlans(data);
    console.log(data);
  };
  useEffect(() => {
    fetchPaymentPlan();
  }, []);

  const handleNextStep = async () => {
    const response = await sendRequest("post", "account/plan", {
      plan_id: activePlan.index + 1,
    });

    console.log(response);
    if (response) {
      setRegistrationSteps((prev: any) => ({ ...prev, plan_id: activePlan.index + 1 }));
      setForceFetch((prev: boolean) => !prev);
      navigate("/onboard/profile-setup");
      console.log("Working");
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <Swiper navigation={true} modules={[Navigation]} className={s.subscription_swiper}>
            {plans.map((el: any, i: number) => (
              <SwiperSlide key={`payment plant map registration ${i}`} className={s.swiper_slide}>
                <PaymentPlan
                  buttonStyles={getButtonStyles(i)}
                  backgroundStyles={paymentStyles}
                  el={el}
                  handleClick={() => handleButtonClick(i)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Button
            text={isLoading ? t("Loading") : t("Next")}
            type="button"
            handleClick={() => handleNextStep()}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default OnboardPaymentPlan;
