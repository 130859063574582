import React, { useState } from "react";
import Domain from "./Domain/Domain";
import MiniNav from "../../components/Nav/MiniNav/MiniNav";
import s from "./Settings.module.scss";
import Personalization from "./Personalization/Personalization";
import WorkSpace from "./WorkSpace/WorkSpace";
import Profile from "./Profile/Profile";
import RenderActionComponent from "../../components/RenderActionComponent/RenderActionComponent";
import { useNavigate } from "react-router-dom";
import { TNavigationItem } from "./types";
import { useTranslation } from "react-i18next";

const actions: Record<string, JSX.Element> = {
  profile: <Profile />,
  domain: <Domain />,
  personalization: <Personalization />,
  workspace: <WorkSpace />,
};

const Settings = () => {
  const { t } = useTranslation();
  const navigationMenu: TNavigationItem[] = [
    {
      name: t("Account"),
      link: "profile",
      ava: "profile",
      premium: false,
    },
    {
      name: t("Work area"),
      link: "workspace",
      ava: "work_space",
      premium: false,
    },
    {
      name: t("Personalisation"),
      link: "personalization",
      ava: "personalization",
      premium: false,
    },
    {
      name: t("Domain"),
      link: "domain",
      ava: "domain",
      premium: true,
    },
  ];
  const [actionState, setActionState] = useState<string>("profile");
  const navigate = useNavigate();

  const setAction = (newAction: string) => {
    setActionState(newAction);
    navigate(`/settings/${newAction}`);
  };

  return (
    <div className={s.container}>
      <MiniNav
        navigation={navigationMenu}
        action={actionState}
        setAction={setAction}
      />
      <RenderActionComponent actions={actions} action={actionState} />
    </div>
  );
};

export default Settings;
