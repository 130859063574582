import { fabric } from "fabric";

/**
 * Calculates the maximum zoom level such that no object on the canvas touches the edges.
 *
 * @param canvas - The fabric.js canvas instance
 * @param canvasWidth - The width of the canvas
 * @param canvasHeight - The height of the canvas
 * @returns {number} - The maximum zoom factor that keeps all objects within the canvas boundaries.
 */
export const calculateMaxZoom = (
  canvas: fabric.Canvas,
  canvasWidth: number,
  canvasHeight: number
): number => {
  if (!canvas) return 1;

  let maxZoomFactor = Infinity;

  canvas.getObjects().forEach((obj) => {
    const objectWidth = obj.getScaledWidth();
    const objectHeight = obj.getScaledHeight();

    const leftEdgeDistance = Math.abs(obj.left! - objectWidth / 2);
    const rightEdgeDistance = Math.abs(obj.left! + objectWidth / 2);
    const topEdgeDistance = Math.abs(obj.top! - objectHeight / 2);
    const bottomEdgeDistance = Math.abs(obj.top! + objectHeight / 2);

    const horizontalZoom = canvasWidth / (2 * Math.max(leftEdgeDistance, rightEdgeDistance));
    const verticalZoom = canvasHeight / (2 * Math.max(topEdgeDistance, bottomEdgeDistance));

    // Take the minimum zoom factor to ensure all objects fit within the canvas
    const objectMaxZoom = Math.min(horizontalZoom, verticalZoom);

    // Update the maxZoomFactor to be the smallest zoom that fits all objects
    maxZoomFactor = Math.min(maxZoomFactor, objectMaxZoom);
  });

  // Return the max zoom factor; if no objects are found, return a default zoom of 1.
  return isFinite(maxZoomFactor) ? maxZoomFactor : 1;
};

/**
 * Zooms the canvas to the maximum level where no objects touch the edges.
 *
 * @param canvas - The fabric.js canvas instance
 * @param canvasWidth - The width of the canvas
 * @param canvasHeight - The height of the canvas
 */
export const zoomToMaxFit = (canvas: fabric.Canvas, canvasWidth: number, canvasHeight: number) => {
  const maxZoom = calculateMaxZoom(canvas, canvasWidth, canvasHeight);
  zoomCanvas(canvas, maxZoom);
};

/**
 * Zooms the canvas while keeping the 0,0 point at the center.
 *
 * @param canvas - The fabric.js canvas instance
 * @param zoomFactor - The zoom level (e.g., 1 for 100%, 2 for 200%)
 */
export const zoomCanvas = (canvas: fabric.Canvas, zoomFactor: number) => {
  if (canvas) {
    const zoom = zoomFactor;

    canvas.setZoom(zoom);

    const center = canvas.getCenter();

    if (canvas.viewportTransform) {
      canvas.viewportTransform[4] = center.left;
      canvas.viewportTransform[5] = center.top;
    }

    canvas.renderAll();
  }
};
