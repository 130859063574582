// updateClipPath.js
import { fabric } from "fabric";
const isOverlappingCircle = (obj: any, workingArea: any) => {
  const objCenter = {
    x: obj.left + obj.radius,
    y: obj.top + obj.radius,
  };
  const workingAreaCenter = {
    x: workingArea.left + workingArea.radius,
    y: workingArea.top + workingArea.radius,
  };

  const distance = Math.sqrt(
    Math.pow(objCenter.x - workingAreaCenter.x, 2) + Math.pow(objCenter.y - workingAreaCenter.y, 2)
  );

  const totalRadii = obj.radius + workingArea.radius;

  return distance <= totalRadii;
};

const isOverlappingRect = (obj: any, workingArea: any) => {
  const objBoundingBox = obj.getBoundingRect(true);
  const areaBoundingBox = {
    left: workingArea.left,
    top: workingArea.top,
    width: workingArea.width,
    height: workingArea.height,
  };

  return (
    objBoundingBox.left + objBoundingBox.width > areaBoundingBox.left &&
    objBoundingBox.left < areaBoundingBox.left + areaBoundingBox.width &&
    objBoundingBox.top + objBoundingBox.height > areaBoundingBox.top &&
    objBoundingBox.top < areaBoundingBox.top + areaBoundingBox.height
  );
};
const createRoundedRectPath = (rect: any) => {
  const { width, height, rx, ry } = rect;
  const pathData = [
    ["M", rx, 0],
    ["L", width - rx, 0],
    ["C", width, 0, width, 0, width, ry],
    ["L", width, height - ry],
    ["C", width, height, width, height, width - rx, height],
    ["L", rx, height],
    ["C", 0, height, 0, height, 0, height - ry],
    ["L", 0, ry],
    ["C", 0, 0, 0, 0, rx, 0],
    ["Z"],
  ];
  return new fabric.Path(pathData.map((segment) => segment.join(" ")).join(" "), {
    left: rect.left,
    top: rect.top,
    originX: "center",
    originY: "center",
    absolutePositioned: true,
  });
};
const updateClipPath = (canvas: any, obj: any) => {
  if (!obj) return;
  if (
    obj?.get("level") === 1 ||
    obj?.get("level") === 2 ||
    obj?.get("level") === 3 ||
    obj?.get("level") === 5
  )
    return;

  const workingArea = canvas.getObjects().find((o: any) => o.get("level") === 2);

  if (!obj || !workingArea) return;

  const isClone = obj.get("level") === 3;
  if (!isClone) {
    let clipPath;
    let isCompletelyOutside;
    if (workingArea.type === "rect" && (workingArea.rx > 0 || workingArea.ry > 0)) {
      // Create a clipping path for a rounded rectangle
      clipPath = createRoundedRectPath({
        left: workingArea.left,
        top: workingArea.top,
        rx: workingArea.rx,
        ry: workingArea.ry,
        width: workingArea.width * workingArea.scaleX,
        height: workingArea.height * workingArea.scaleY,
      });
      isCompletelyOutside = !isOverlappingRect(obj, workingArea);
    } else if (workingArea.type === "rect") {
      clipPath = new fabric.Rect({
        left: workingArea.left,
        top: workingArea.top,
        width: workingArea.width * workingArea.scaleX,
        height: workingArea.height * workingArea.scaleY,
        originX: "center",
        originY: "center",
        absolutePositioned: true,
      });
      isCompletelyOutside = !isOverlappingRect(obj, workingArea);
    } else if (workingArea.type === "circle") {
      const scaledRadius = workingArea.radius * workingArea.scaleX;

      clipPath = new fabric.Circle({
        left: workingArea.left,
        top: workingArea.top,
        radius: scaledRadius,
        originX: "center",
        originY: "center",
        absolutePositioned: true,
      });

      isCompletelyOutside = !isOverlappingCircle(obj, workingArea);
    } else {
      return;
    }

    obj.selectable = true;
    obj.hasControls = true;
    obj.clipPath = clipPath;
    obj.dirty = true;
  }

  canvas.requestRenderAll();
};

export default updateClipPath;
