import React, { useContext, useState } from "react";
import s from "./ImportFile.module.scss";
import Button from "../../../Button/Button";
import { useTranslation } from "react-i18next";
import { generateRandomHexString } from "../../../../helper/generateRandomHexString";

interface IImportFile {
  setFileToSend: (file: any) => void;
  onFileSelectedAndInvoke?: (file: any) => void;
}

const ImportFile: React.FC<IImportFile> = ({ setFileToSend, onFileSelectedAndInvoke }) => {
  const { t } = useTranslation();

  const [fileName, setFileName] = useState<string>("");

  const handleCreateTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name);
      const draft_id = generateRandomHexString(32);
      const fileData = { file, draft_id };

      setFileToSend(fileData);

      if (onFileSelectedAndInvoke) {
        onFileSelectedAndInvoke(fileData);
      }
    }
  };

  return (
    <div className={s.container}>
      <span>{t("ImportFileComponent.one")}</span>
      <span>{t("ImportFileComponent.two")}</span>
      <Button
        text={t("Select file")}
        type="input"
        onFileChange={handleCreateTemplate}
        name={fileName}
      />
      <span>
        {t("ImportFileComponent.three")}
        <br /> {t("ImportFileComponent.four")}
      </span>
    </div>
  );
};

export default ImportFile;
