import React from "react";
import s from "./PaperComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
interface IPaperComponent {
  paper: {
    imgSize: string;
    size: string;
    dpi: string;
  };
  handleClick: () => void;
}
const PaperComponent = ({ paper, handleClick }: IPaperComponent) => {
  return (
    <div className={s.container} onClick={() => handleClick()}>
      <ComponentBackground>
        <div className={s.img_container}>
          <span>{paper?.imgSize}</span>
        </div>
      </ComponentBackground>
      <div className={s.info}>
        <span>{paper?.dpi}</span>
        <span>{paper?.size}</span>
      </div>
    </div>
  );
};

export default PaperComponent;
