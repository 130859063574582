import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { TIconMap } from "./types";
import StorageIcon from "@mui/icons-material/Storage";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";

import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PaymentIcon from "@mui/icons-material/Payment";

export const iconMap: TIconMap = {
  profile: AccountBoxIcon,
  work_space: StorageIcon,
  personalization: DisplaySettingsIcon,
  domain: LanguageIcon,
  settings: SettingsIcon,
  history: HistoryEduIcon,
  payment: PaymentIcon,
};
