import React, { useContext, useEffect, useState } from "react";
import s from "./WorkShopProduct.module.scss";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import FabricJSContextNew from "../../../../../../core/context/FabricJSContextNew";

import RenderLayers from "./components/RenderLayers";
import DevMode from "./DevMode";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import AuthContext from "../../../../../../core/context/AuthContext";
import addBorderRadiusToWARect from "../../../../fabric-functions/WorkingAreaLogic/BorderRadiousRect";
import addWARect from "../../../../fabric-functions/WorkingAreaLogic/RectWA";
import addWACircle from "../../../../fabric-functions/WorkingAreaLogic/CircleWA";

const WorkShopProduct = () => {
  const { t } = useTranslation();
  const { canvasContext, activeGlobalState, setActiveGlobalState } = useContext(FabricJSContextNew);
  const { user } = useContext(AuthContext);
  const handleBorderRadious = (e: any) => {
    addBorderRadiusToWARect(canvasContext, e.target.value);
  };

  const [dev, setDev] = useState(false);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [unit, setUnit] = useState("MM");
  const [dpi, setDpi] = useState("");
  const [dpiInput, setDpiInput] = useState("");

  const handleDimensionChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "width") {
      setWidth(value);
    } else if (name === "height") {
      setHeight(value);
    }
  };

  const handleDpiInputChange = (e: any) => {
    setDpiInput(e.target.value);
  };

  const handleDpiInputBlur = () => {
    setDpi(dpiInput);
    calculateDimensionsByDpi(dpiInput);
    calculateWorkingAreaDimensions(dpiInput);
  };

  const handleDpiInputKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleDpiInputBlur();
    }
  };

  const handleUnitChange = (event: SelectChangeEvent) => {
    setUnit(event.target.value as string);
  };

  const calculateDpi = () => {
    const widthNumber = parseFloat(width);
    const heightNumber = parseFloat(height);

    if (!isNaN(widthNumber) && !isNaN(heightNumber) && canvasContext) {
      const widthInInches = unit === "MM" ? widthNumber / 25.4 : widthNumber;
      const heightInInches = unit === "MM" ? heightNumber / 25.4 : heightNumber;

      const canvasWidthInPixels = canvasContext.getWidth();
      const canvasHeightInPixels = canvasContext.getHeight();

      const dpiWidth = canvasWidthInPixels / widthInInches;
      const dpiHeight = canvasHeightInPixels / heightInInches;

      const calculatedDpi = Math.round(Math.min(dpiWidth, dpiHeight));
      setDpi(calculatedDpi.toString());
      setDpiInput(calculatedDpi.toString());
    }
  };

  const calculateDimensionsByDpi = (newDpi: string) => {
    const dpiNumber = Math.round(parseFloat(newDpi));
    if (!isNaN(dpiNumber) && canvasContext) {
      const canvasWidthInPixels = canvasContext.getWidth();
      const canvasHeightInPixels = canvasContext.getHeight();

      const widthInInches = canvasWidthInPixels / dpiNumber;
      const heightInInches = canvasHeightInPixels / dpiNumber;

      const newWidth = unit === "MM" ? (widthInInches * 25.4).toFixed(2) : widthInInches.toFixed(2);
      const newHeight =
        unit === "MM" ? (heightInInches * 25.4).toFixed(2) : heightInInches.toFixed(2);

      setWidth(newWidth);
      setHeight(newHeight);
      console.log({ newWidth, newHeight, newDpi });
    }
  };

  const calculateWorkingAreaDimensions = (newDpi: string) => {
    const dpiNumber = Math.round(parseFloat(newDpi));
    if (!isNaN(dpiNumber) && canvasContext) {
      let widthValue = parseFloat(width);
      let heightValue = parseFloat(height);

      // Convert to inches if unit is in millimeters
      if (unit === "MM") {
        widthValue = widthValue / 25.4;
        heightValue = heightValue / 25.4;
      }

      const newWorkingAreaWidthPixels = Math.round(widthValue * dpiNumber);
      const newWorkingAreaHeightPixels = Math.round(heightValue * dpiNumber);
      console.log({ newWorkingAreaWidthPixels, newWorkingAreaHeightPixels });
      // Find the working area object and adjust its size
      const workingArea = canvasContext.getObjects().find((obj: any) => obj.name === "workingArea");
      if (workingArea) {
        workingArea.set({
          width: newWorkingAreaWidthPixels,
          height: newWorkingAreaHeightPixels,
        });
        canvasContext.renderAll();
      }
    }
  };

  useEffect(() => {
    calculateDpi();
  }, [width, height, unit, canvasContext]);

  const setViewNameHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newName = e.target.value;

    setActiveGlobalState((prevState: any) => {
      return {
        ...prevState,
        selectedView: {
          ...prevState.selectedView,
          name: newName,
        },
      };
    });
  };

  return (
    <div className={s.container}>
      <div className={s.top}>
        <h4>{t("WorkShopProduct.Preview")}</h4>
        <div className={s.top_container}>
          <div className={s.img_container}>
            <img src={activeGlobalState.generateThumbnail} />
          </div>
          {user ? (
            <TextField
              size="small"
              label={t("WorkShopProduct.Name")}
              value={activeGlobalState?.selectedView?.name || ""}
              onChange={(e) => setViewNameHandler(e)}
            />
          ) : null}
        </div>
      </div>

      <div className={s.middle}>
        <h4>{t("WorkShopProduct.Print area")}</h4>

        <div>
          <span>{t("WorkShopProduct.Shape")}</span>
          <div className={s.shape_container} onClick={() => addWARect(canvasContext)}>
            <CropSquareIcon fontSize="large" />
          </div>
          <div className={s.shape_container} onClick={() => addWACircle(canvasContext)}>
            <RadioButtonUncheckedIcon fontSize="large" />
          </div>
        </div>
        {user ? (
          <div className={s.input_container}>
            <TextField
              size="small"
              label={t("Width")}
              name="width"
              value={width}
              onChange={handleDimensionChange}
            />
            <TextField
              size="small"
              label={t("Height")}
              name="height"
              value={height}
              onChange={handleDimensionChange}
            />
            <FormControl fullWidth>
              <InputLabel id="unit-select-label">Unit</InputLabel>
              <Select
                labelId="unit-select-label"
                id="unit-select"
                value={unit}
                label="Unit"
                size="small"
                onChange={handleUnitChange}
              >
                <MenuItem value="MM">MM</MenuItem>
                <MenuItem value="INCHES">{t("Inches")}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              size="small"
              label="DPI"
              value={dpiInput}
              onChange={handleDpiInputChange}
              onBlur={handleDpiInputBlur}
              onKeyPress={handleDpiInputKeyPress}
            />
            <TextField
              size="small"
              label={t("WorkShopProduct.Corner radious")}
              type="number"
              onChange={handleBorderRadious}
            />
          </div>
        ) : null}
      </div>
      <div className={s.bottom}>
        <h4>{t("WorkShopProduct.Layers")}</h4>
        <div>
          <RenderLayers />
        </div>
      </div>

      <button onClick={() => setDev(!dev)} style={{ marginTop: "auto", width: "10%" }}>
        DEV
      </button>
      {dev ? <DevMode /> : null}
    </div>
  );
};

export default WorkShopProduct;
