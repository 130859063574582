import React, { useContext } from "react";
import { useLocation, useMatches, useNavigate } from "react-router-dom";
import s from "./Breadcrumbs.module.scss";
import uuid from "react-uuid";
import AppContext from "../../../core/context/AppContext";
import { useTranslation } from "react-i18next";

interface IBreadcrumbs {
  styleOption?: number;
  folderPath: any[];
  setFolderPath: any;
  type?: string;
}

// Breadcrumbs component manages navigation through folder paths within the app.
// `styleOption` determines which style to apply:
// 1. Default breadcrumb style used across the dashboard.
// 2. Special breadcrumb style used when navigating inside a modal.
const Breadcrumbs = ({ styleOption, folderPath, setFolderPath, type }: IBreadcrumbs) => {
  const location = useLocation(); // `useLocation` gives us the current URL path.
  const navigate = useNavigate(); // `useNavigate` is used for programmatic navigation between pages.
  const routerPath = location.pathname.split("/")[1]; // Extracts the first part of the current path to identify the base route.
  const { t } = useTranslation();
  // This function checks if navigation should be allowed for certain routes.
  // We only allow navigation for "projects" and "products" paths.
  const shouldNavigateForPath = () => {
    const allowedPaths = ["projects", "products"];
    return allowedPaths.includes(routerPath); // Returns true if the current path is allowed, false otherwise.
  };

  let matches = useMatches(); // `useMatches` helps match the current route and allows us to generate crumbs based on the route structure.

  let crumbs = matches
    .filter((match: any) => Boolean(match.handle?.crumb)) // Filters out matches that don't have a `crumb` handle.
    .map((match: any) =>
      typeof match.handle.crumb === "function" ? match.handle.crumb(match.data) : match.handle.crumb
    );
  if (type === "folder") {
    crumbs[0] = t("Root"); // Replace the first element with "root"
  }
  // If there is a folder path in context, append the folder names to the breadcrumb trail.
  if (folderPath && folderPath.length > 0) {
    crumbs = [...crumbs, ...folderPath.map((folder: any) => folder.name)];
  }

  // Function to handle clicking on breadcrumbs:
  // - When clicking the root (index 0), the folder path resets.
  // - For other clicks, it updates the folder path state accordingly.
  // - Navigation only happens if the current path is allowed (projects or products).
  const handleCrumbClick = (index: number) => {
    if (index === 0) {
      // Reset the folder path and conditionally navigate based on allowed paths.
      setFolderPath([]);
      if (shouldNavigateForPath()) {
        navigate(`/${routerPath}`); // Only navigate if the path is allowed.
      }
    } else {
      // Update folder path up to the clicked crumb index.
      const newPath = folderPath.slice(0, index);
      setFolderPath(newPath);

      const clickedFolder = folderPath[index - 1]; // Get the folder corresponding to the clicked breadcrumb.

      if (shouldNavigateForPath()) {
        // If the path is allowed, navigate to the folder using its token.
        navigate(`/${routerPath}/folder/${clickedFolder.token}`);
      }
    }
  };

  // Render the breadcrumb trail.
  return (
    <div className={styleOption === 1 ? s.containerOne : s.containerTwo}>
      {crumbs.map((crumb, index) => (
        <React.Fragment key={uuid()}>
          {/* Each crumb is clickable and triggers the `handleCrumbClick` based on its index. */}
          <span
            onClick={() => handleCrumbClick(index)}
            className={index === 0 ? s.rootCrumb : s.crumb} // Apply different styles for the root crumb.
          >
            {crumb}
          </span>
          {index < crumbs.length - 1 && <span className={s.separator}> \ </span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
