import React, { useContext, useEffect, useState } from "react";
import s from "../EditSettings.module.scss";
import TopOfModal from "../../TopOfModal";
import ModalHeader from "../../ModalHeader";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { usePostRequest } from "../../../../core/api/usePostRequest";
import Button from "../../../Button/Button";
import AuthContext from "../../../../core/context/AuthContext";

import { isEmptyString, validateEmail } from "../../../../helper/validation";
import { useApiRequest } from "../../../../core/api/useApiRequest";
const buttonStyle = { marginLeft: "auto", width: "200px" };

const EditPassword = () => {
  const { t } = useTranslation();
  const { sendRequest } = useApiRequest();
  const [input, setInput] = useState<any>({
    password: "",
    password_confirmation: "",
  });
  const { setUser, user } = useContext(AuthContext);
  const [isPassError, setIsPassErr] = useState(false);
  const [isPassTwoErr, setIsPassTwoErr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const formIsValid = isPassError && isPassTwoErr;
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handlePostData = async () => {
    if (!formIsValid) return;

    const response: any = await sendRequest("post", "/account/profile", input);
    console.log(response);
    // setUser(response.data);
  };

  return (
    <div className={s.container}>
      <ModalHeader header={t("Edit password")} />
      <TextField
        name="password"
        type={showPassword ? "text" : "password"}
        onChange={(e) => inputHandler(e)}
        className={s.input}
        label={t("Password")}
        variant="outlined"
        onBlur={() => setIsPassErr(isEmptyString(input.password.toString()))}
        error={isPassError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        name="password_confirmation"
        type={showConfirmPassword ? "text" : "password"}
        onChange={(e) => inputHandler(e)}
        className={s.input}
        label={t("Repeat password")}
        variant="outlined"
        onBlur={() => setIsPassTwoErr(isEmptyString(input.password_confirmation.toString()))}
        error={isPassTwoErr}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        text={t("Send")}
        type="button"
        handleClick={() => handlePostData()}
        styles={buttonStyle}
      />
    </div>
  );
};

export default EditPassword;
