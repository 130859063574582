import React, { useContext, useEffect, useState } from "react";
import s from "./TopToolsText.module.scss";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import { fonts } from "./fonts";
import { Autocomplete, TextField } from "@mui/material";
import IncreaseFont from "../../../../../../../components/IncreaseFont/IncreaseFont";
import Wheel from "@uiw/react-color-wheel";
import { hsvaToHex, hexToHsva } from "./convertColors"; // Ensure you have a conversion from hex to HSVA
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import changeText from "../../../../../fabric-functions/ChangeText";

const TopToolsText = () => {
  const { canvasContext, canvasActiveObject } = useContext(FabricJSContextNew);

  const [hsva, setHsva] = useState({ h: 214, s: 43, v: 90, a: 1 });
  const [showColorPallete, setShowCollorPallete] = useState<boolean>(false);
  const [palletCurrentColor, setPalletCurrentColor] = useState<any>();

  const colorHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    setShowCollorPallete(!showColorPallete);
  };

  const onColorChange = (color: any) => {
    const newHsva = { ...hsva, ...color.hsva };
    setHsva(newHsva);
    const newHexColor = hsvaToHex(newHsva);
    changeText(canvasContext, { fill: newHexColor });
  };

  const boldnessHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      const newFontWeight = activeObject.fontWeight === "normal" ? "bold" : "normal";
      changeText(canvasContext, { fontWeight: newFontWeight });
    }
  };

  const italicHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      const newFontStyle = activeObject.fontStyle === "normal" ? "italic" : "normal";
      changeText(canvasContext, { fontStyle: newFontStyle });
    }
  };

  const underlineHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      const newUnderline = !activeObject.underline;
      changeText(canvasContext, { underline: newUnderline });
    }
  };

  const alignHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      const newTextAlign = activeObject.textAlign === "left" ? "center" : "left";
      changeText(canvasContext, { textAlign: newTextAlign });
    }
  };

  const fontStyleHandler = (_event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value) {
      changeText(canvasContext, { fontFamily: value });
    }
  };

  useEffect(() => {
    if (canvasActiveObject?.objectJSON?.fill) {
      const newHsva = hexToHsva(canvasActiveObject.objectJSON.fill);
      setHsva(newHsva);
    }
  }, [canvasActiveObject]);

  const [activeObject, setActiveObject] = useState<any>(null);
  useEffect(() => {
    const activeObject = canvasActiveObject?.objectJSON;
    setActiveObject(activeObject);
  }, [canvasContext]);
  const initialValue = fonts.includes(activeObject?.fontFamily) ? activeObject?.fontFamily : null;

  return (
    <div className={s.container}>
      <div className={s.color_pallet_container} onClick={colorHandler}>
        <div
          className={s.current_color}
          style={{ background: activeObject ? activeObject.fill : "#000000" }}
        ></div>
        {showColorPallete ? (
          <div className={s.pallet}>
            <Wheel className={s.pallet} color={hsva} onChange={onColorChange} />
          </div>
        ) : null}
      </div>
      <Autocomplete
        className={s.autocomplete}
        disablePortal
        id="combo-box-demo"
        options={fonts}
        onChange={fontStyleHandler}
        value={initialValue}
        size="small"
        renderInput={(params) => <TextField {...params} sx={{ borderRadius: "4px" }} />}
      />
      <IncreaseFont fontSize={activeObject?.fontSize || "14"} />

      <div className={s.icon_container}>
        <FormatBoldIcon fontSize="medium" onClick={boldnessHandler} />
      </div>

      <div className={s.icon_container}>
        <FormatItalicIcon fontSize="medium" onClick={italicHandler} />
      </div>
      <div className={s.icon_container}>
        <FormatUnderlinedIcon fontSize="medium" onClick={underlineHandler} />
      </div>

      <div className={s.icon_container}>
        <FormatAlignCenterIcon fontSize="medium" onClick={alignHandler} />
      </div>
    </div>
  );
};

export default TopToolsText;
