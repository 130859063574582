import { createContext, ReactNode, useState, useEffect, useContext } from "react";
import { useGetRequest } from "../api/useGetRequest";
import AuthContext from "./AuthContext";

interface ProjectContextValue {
  projects: any;
  setProjects: any;
  forceReloadFlag: boolean;
  setForceReloadFlag: (flag: boolean | ((prevFlag: boolean) => boolean)) => void;
}

const ProjectContext = createContext<ProjectContextValue>({
  projects: null,
  setProjects: () => {},
  forceReloadFlag: false,
  setForceReloadFlag: () => {},
});

export const ProjectContextProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useContext(AuthContext);
  const [projects, setProjects] = useState<any>(null);
  const [forceReloadFlag, setForceReloadFlag] = useState<boolean>(false);

  const { getData } = useGetRequest();

  const fetchProjects = async () => {
    if (!user) return;
    const response = await getData("/creator/2d/projects");
    setProjects(response?.data?.data);
  };

  useEffect(() => {
    fetchProjects();
  }, [forceReloadFlag, user]);
  return (
    <ProjectContext.Provider value={{ projects, setProjects, forceReloadFlag, setForceReloadFlag }}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
