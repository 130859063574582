import React, { useContext } from "react";
import s from "./TopToolsGraphics.module.scss";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
const TopToolsGraphics = () => {
  // const { changeImgPropertiesContext, activeObject } = useContext(FabricJSContext);

  const flixXAxisHandler = () => {
    // changeImgPropertiesContext({ flipX: !activeObject.flipX });
  };
  const flixYAxisHandler = () => {
    // changeImgPropertiesContext({ flipY: !activeObject.flipY });
  };
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <div className={s.icon_container}>
        <Tooltip title={t("GraphicsToolBar.flipH")} placement="bottom">
          <VerticalAlignCenterIcon
            className={s.horizontal}
            onClick={() => flixXAxisHandler()}
            fontSize="small"
          />
        </Tooltip>
      </div>
      <div className={s.icon_container}>
        <Tooltip title={t("GraphicsToolBar.flipV")} placement="bottom">
          <VerticalAlignCenterIcon onClick={() => flixYAxisHandler()} fontSize="small" />
        </Tooltip>
      </div>
      <div className={s.icon_container}>
        <Tooltip title={t("GraphicsToolBar.flipX")} placement="bottom">
          <AlignVerticalCenterIcon className={s.horizontal} fontSize="small" />
        </Tooltip>
      </div>
      <div className={s.icon_container}>
        <Tooltip title={t("GraphicsToolBar.flipY")} placement="bottom">
          <AlignVerticalCenterIcon fontSize="small" />
        </Tooltip>
      </div>
    </div>
  );
};

export default TopToolsGraphics;
