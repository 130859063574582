import React, { useContext, useState, useEffect } from "react";
import s from "./OnboardProfile.module.scss";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../core/context/AuthContext";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import { validateEmail, isEmptyString } from "../../../helper/validation";
import { useApiRequest } from "../../../core/api/useApiRequest";

const OnboardProfile = () => {
  const { registrationStep, setUser, user } = useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const [input, setInput] = useState({
    name: "",
    password: "",
    password_confirmation: "",
    email: email,
  });
  const handleGetEmail = () => {
    const retriveEmail = localStorage.getItem("registrationEmail") || "";
    console.log(retriveEmail, "retriveEmail");
    if (retriveEmail) {
      setEmail(retriveEmail);
    } else {
      setEmail(registrationStep?.email);
    }
  };
  useEffect(() => {
    handleGetEmail();
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordConfirmationValid, setIsPasswordConfirmationValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  const { t } = useTranslation();
  const { sendRequest, isLoading } = useApiRequest();
  const navigate = useNavigate();

  const path = registrationStep.plan_id === 1 ? "/dashboard" : "/onboard/workspace";

  useEffect(() => {
    // Validate the entire form on input changes
    setIsFormValid(
      !isEmptyString(input?.name) &&
        !isEmptyString(input?.password) &&
        input?.password === input?.password_confirmation
    );
  }, [input]);

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
      email: email,
    });

    // Validate individual inputs on change
    if (name === "name") {
      setIsNameValid(!isEmptyString(value));
    } else if (name === "password") {
      setIsPasswordValid(!isEmptyString(value));
      setIsPasswordConfirmationValid(value === input?.password_confirmation);
    } else if (name === "password_confirmation") {
      setIsPasswordConfirmationValid(value === input?.password);
    }
  };

  const handleCreateProfile = async () => {
    if (!isFormValid) return;

    const response: any = await sendRequest("post", "account/profile", input);
    if (!response) {
      console.log(response, "response");
      return;
    }
    console.log(response);
    setUser(response.data);
    localStorage.removeItem("registrationEmail");
    navigate(path);
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <span>{t("Profile")}</span>
          <TextField
            name="name"
            onChange={inputHandler}
            className={s.input}
            id="outlined-basic"
            label={t("User name")}
            variant="outlined"
            color="primary"
            error={!isNameValid}
            value={input?.name}
          />
          <TextField
            name="email"
            value={email}
            className={s.input}
            id="outlined-basic"
            label={t("Address email")}
            variant="outlined"
            disabled
          />
          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={inputHandler}
            className={s.input}
            label={t("Password")}
            variant="outlined"
            error={!isPasswordValid}
            value={input?.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="password_confirmation"
            type={showConfirmPassword ? "text" : "password"}
            onChange={inputHandler}
            className={s.input}
            label={t("Repeat password")}
            variant="outlined"
            error={!isPasswordConfirmationValid}
            value={input?.password_confirmation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={s.button_container}>
            <Button
              text={isLoading ? t("Loading") : t("Next")}
              type="button"
              handleClick={handleCreateProfile}
              disabled={!isFormValid}
              isLoading={isLoading}
            />
          </div>
          <div className="solid-line"></div>
          <span>
            {t("OnboardProfileComponent.one")}{" "}
            <Link to="/dashboard">{t("OnboardProfileComponent.two")}</Link>
          </span>
        </div>
      </div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default OnboardProfile;
