import React, { useContext, useEffect, useState } from "react";
import s from "../EditSettings.module.scss";
import ModalHeader from "../../ModalHeader";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePostRequest } from "../../../../core/api/usePostRequest";
import Button from "../../../Button/Button";
import AuthContext from "../../../../core/context/AuthContext";
import { isEmptyString } from "../../../../helper/validation";
import {
  uniqueID,
  useNotification,
} from "../../../../core/context/notifications/NotificationProvider";
import { useApiRequest } from "../../../../core/api/useApiRequest";

const buttonStyle = { marginLeft: "auto", width: "200px" };

const defaultValue = {
  name: "",
  slug: "",
};

interface IEditWorkSpace {
  setCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditWorkSpace = ({ setCloseModal }: IEditWorkSpace) => {
  const { t } = useTranslation();
  const { sendRequest, isLoading } = useApiRequest();
  const notification = useNotification();
  const [input, setInput] = useState(defaultValue);
  const { user, setUser } = useContext(AuthContext);

  const [isNameValid, setIsNameValid] = useState(false);
  const [isSlugValid, setIsSlugValid] = useState(false);

  useEffect(() => {
    if (user && user.workspace) {
      const updatedValues = {
        name: user.workspace.name || "",
        slug: user.workspace.slug || "",
      };
      setInput((prev) => ({ ...prev, ...updatedValues }));
      setIsNameValid(!isEmptyString(updatedValues.name));
      setIsSlugValid(!isEmptyString(updatedValues.slug));
    }
  }, [user]);

  useEffect(() => {
    console.log("Updated input values:", input);
    setIsNameValid(!isEmptyString(input.name));
    setIsSlugValid(!isEmptyString(input.slug));
  }, [input]);

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));

    if (name === "name") {
      setIsNameValid(!isEmptyString(value));
    } else if (name === "slug") {
      setIsSlugValid(!isEmptyString(value));
    }
  };

  const handlePostData = async () => {
    if (!isNameValid || !isSlugValid) return;

    const response = await sendRequest("post", "/account/workspace", input);
    console.log(response, "response");
    if (!response) return;
    setUser((prevState: any) => ({
      ...prevState,
      workspace: response.data,
    }));

    setCloseModal((prev) => !prev);
    notification({
      type: "SUCCESS",
      message: t("Request successful"),
      id: uniqueID(),
    });
    console.log(response);
  };

  return (
    <div className={s.container}>
      <ModalHeader header={t("Edit workspace")} />
      <TextField
        onChange={inputHandler}
        value={input.name}
        name="name"
        label={t("Name")}
        onBlur={() => setIsNameValid(!isEmptyString(input.name))}
        error={!isNameValid}
      />
      <TextField
        onChange={inputHandler}
        value={input.slug}
        name="slug"
        label={t("Slug")}
        onBlur={() => setIsSlugValid(!isEmptyString(input.slug))}
        error={!isSlugValid}
      />
      <Button
        text={isLoading ? t("Loading") : t("Send")}
        type="button"
        handleClick={handlePostData}
        styles={buttonStyle}
        disabled={!isNameValid || !isSlugValid || isLoading}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditWorkSpace;
