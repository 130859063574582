import React, { useContext, useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Info as InfoIcon,
  Redo as RedoIcon,
  Undo as UndoIcon,
  Save as SaveIcon,
  Download as DownloadIcon,
  Share as ShareIcon,
  Login as LoginIcon,
} from "@mui/icons-material";
import s from "./DesignStudioTopBar.module.scss";
import FabricJSContextNew from "../../../../core/context/FabricJSContextNew";
import AuthContext from "../../../../core/context/AuthContext";
import ProjectContext from "../../../../core/context/ProjectContext";
import ProductContext from "../../../../core/context/ProductContext";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import {
  useNotification,
  uniqueID,
} from "../../../../core/context/notifications/NotificationProvider";

import NormalModal from "../../../Modals/Modal/NormalModal";
import ModalWrapper from "../../../Modals/Modal.wrapper";
import ShareItem from "../../../Modals/ShareItem/ShareItem";
import AppContext from "../../../../core/context/AppContext";
import { convertDataToPostProject } from "../../../../features/Fabric-JS/fabric-functions/POST/ConvertToPostDataProject";
import { convertDataToPostProduct } from "../../../../features/Fabric-JS/fabric-functions/POST/ConvertToPostDataProduct";
import {
  redo,
  saveState,
  undo,
} from "../../../../features/Fabric-JS/fabric-functions/redoUndoStack";
import {
  exportCanvasToImage,
  exportWorkingAreaToImage,
} from "../../../../features/Fabric-JS/fabric-functions/ExportCanvasToImage";

const DesignStudioTopBar = () => {
  const { canvasContext, activeGlobalState, setActiveGlobalState } = useContext(FabricJSContextNew);
  const { setProjects } = useContext(ProjectContext);
  const { setProducts } = useContext(ProductContext);
  const { setIsLoadingSave, isLoadingSave, lastDesignStudioLink, setLastDesignStudioLink } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sendRequest } = useApiRequest();
  const notification = useNotification();
  const [openShareModal, setOpenShareModal] = useState(false);

  const handleProjectUpdate = useCallback(async () => {
    try {
      setIsLoadingSave(true);
      if (isLoadingSave) return;
      const readyFormat = convertDataToPostProject(canvasContext, activeGlobalState);
      const batchResponse = await sendRequest(
        "post",
        `/creator/2d/projects/${activeGlobalState.fullJSON.id}/batch`,
        readyFormat
      );
      const publishResponse: any = await sendRequest(
        "post",
        `/creator/2d/projects/${activeGlobalState.fullJSON.id}/publish`
      );

      if (publishResponse?.data) {
        setIsLoadingSave(false);
        notification({ id: uniqueID, type: "SUCCESS", message: "Project Updated" });
        setProjects((prev: any) =>
          prev.map((item: any) =>
            item.id === publishResponse.data.id ? publishResponse.data : item
          )
        );
        setActiveGlobalState((prev: any) => ({ ...prev, fullJSON: publishResponse.data }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    canvasContext,
    activeGlobalState,
    sendRequest,
    notification,
    setProjects,
    setActiveGlobalState,
  ]);

  const handleProductUpdate = useCallback(async () => {
    try {
      setIsLoadingSave(true);
      if (isLoadingSave) return;
      const readyFormat = convertDataToPostProduct(canvasContext, activeGlobalState);
      const batchResponse = await sendRequest(
        "post",
        `/creator/2d/templates/${activeGlobalState.fullJSON.id}/batch`,
        readyFormat
      );
      const publishResponse: any = await sendRequest(
        "post",
        `/creator/2d/templates/${activeGlobalState.fullJSON.id}/publish`
      );

      if (publishResponse?.data) {
        setIsLoadingSave(false);

        const updatedProduct = publishResponse.data;
        setProducts((prev: any) => {
          const exists = prev.some((item: any) => item.id === updatedProduct.id);
          return exists
            ? prev.map((item: any) => (item.id === updatedProduct.id ? updatedProduct : item))
            : [...prev, updatedProduct];
        });

        setActiveGlobalState((prev: any) => ({ ...prev, fullJSON: updatedProduct }));
        notification({ id: uniqueID, type: "SUCCESS", message: "Product successfully updated" });
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    canvasContext,
    activeGlobalState,
    sendRequest,
    notification,
    setProducts,
    setProjects,
    setActiveGlobalState,
  ]);

  useEffect(() => {
    if (canvasContext) {
      // Save initial state
      saveState(canvasContext);

      // Register event listeners to save state when objects are modified
      const handleObjectModified = () => saveState(canvasContext);
      canvasContext.on("object:modified", handleObjectModified);
      canvasContext.on("object:added", handleObjectModified);

      // Cleanup on component unmount
      return () => {
        canvasContext.off("object:modified", handleObjectModified);
        canvasContext.off("object:added", handleObjectModified);
      };
    }
  }, [canvasContext]);

  const handleSave = useCallback(() => {
    if (activeGlobalState?.canvasType === "product") {
      handleProductUpdate();
    } else if (activeGlobalState?.canvasType === "project") {
      handleProjectUpdate();
    }

    //delete is draft from link after we save it.
    const isDraft = lastDesignStudioLink.split("=")[1] === "true";
    setLastDesignStudioLink(lastDesignStudioLink.split("&")[0]);
  }, [activeGlobalState.canvasType, handleProjectUpdate, handleProductUpdate]);

  const handleDownload = () => {
    exportCanvasToImage(canvasContext);
    exportWorkingAreaToImage(canvasContext);
  };
  const location = useLocation();
  const isDesignStudio = location.pathname.includes("design-studio");
  return (
    <>
      <div className={s.container}>
        {user && isDesignStudio ? (
          <>
            <div className={s.style}></div>
            <div>
              <span>Pliki</span>
            </div>
            <div className={s.style}></div>
            <div className={s.div_style}>
              <UndoIcon onClick={() => canvasContext && undo(canvasContext)} />
              <RedoIcon onClick={() => canvasContext && redo(canvasContext)} />
            </div>
            <div className={s.style}></div>
            <div onClick={handleSave}>
              <SaveIcon />
            </div>
            <div className={s.style}></div>
            {/* <div className={s.div_style}>
              <InfoIcon />
              <span>Zmiany zapisane 2min temu</span>
            </div> */}
            {/* <div className={`${s.style} ${s.seperator}`}></div> */}
          </>
        ) : null}

        {user ? (
          <div className={s.button_div}>
            <div className={s.button} onClick={handleDownload}>
              <DownloadIcon />
              <span>{t("Download")}</span>
            </div>
            <div className={s.button} onClick={() => setOpenShareModal(true)}>
              <ShareIcon />
              <span>{t("Share")}</span>
            </div>
          </div>
        ) : (
          <div className={s.button_div}>
            {/* <div className={s.button} onClick={() => setOpenShareModal(true)}>
              <ShareIcon />
              <span>{t("Share")}</span>
            </div> */}
            <div className={s.button} onClick={() => navigate("/login")}>
              <LoginIcon />
              <span>{t("Login")}</span>
            </div>
          </div>
        )}
      </div>
      <NormalModal open={openShareModal} setOpen={setOpenShareModal}>
        <ModalWrapper setOpen={setOpenShareModal}>
          <ShareItem />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default DesignStudioTopBar;
