import React, { useContext } from "react";
import s from "../WorkShopProduct.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Tooltip } from "@mui/material";
import { reorderCanvasObjects } from "../../../../../fabric-functions/UpdateCanvasObjectList";
import changeObjectName from "../../../../../fabric-functions/ManipulateObjects/ChangeObjectName";
import hideObject from "../../../../../fabric-functions/ManipulateObjects/HideObject";
import lockObject from "../../../../../fabric-functions/LockObject";
import deleteObject from "../../../../../fabric-functions/ManipulateObjects/DeleteObject";
import duplicateCanvasObject from "../../../../../fabric-functions/ManipulateObjects/DuplicateObject";

const RenderLayers = () => {
  const { canvasContext, canvasObjectList, setCanvasObjectList } = useContext(FabricJSContextNew);

  const renderLayersProduct = () => {
    return canvasObjectList.filter((obj: any) => obj.level === 1);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(canvasObjectList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCanvasObjectList(items);

    reorderCanvasObjects(canvasContext, items);
  };

  const handleObjectNameChange = (e: any, objectId: string) => {
    changeObjectName(canvasContext, objectId, e.target.value, setCanvasObjectList);
  };

  const handleToggleHideObject = (objectId: string) => {
    hideObject(canvasContext, objectId, setCanvasObjectList);
  };

  const handleToggleLockObjectById = (objectId: string) => {
    lockObject(canvasContext, objectId, setCanvasObjectList);
  };

  const handleDeleteObject = (selectedObjId: string) => {
    deleteObject(canvasContext, selectedObjId, setCanvasObjectList);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="layers">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {renderLayersProduct().map((el: any, i: number) => (
              <Draggable key={el.id} draggableId={el.id} index={i}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={s.layer_container}
                  >
                    {el.id.includes("-img") ? <ImageIcon /> : <FormatBoldIcon />}
                    <input
                      type="text"
                      value={el.type.includes("text") ? el.text : el.name}
                      placeholder={el.type.includes("text") ? el.text : el.name}
                      onChange={(e) => handleObjectNameChange(e, el.id)}
                    />
                    <div className={s.icons}>
                      <Tooltip placement="top" title="Ukryj">
                        <VisibilityIcon onClick={() => handleToggleHideObject(el.id)} />
                      </Tooltip>
                      <Tooltip placement="top" title="Duplikuj">
                        <ContentCopyIcon
                          onClick={() =>
                            duplicateCanvasObject(
                              canvasContext,
                              el.id,
                              setCanvasObjectList,
                              el.level
                            )
                          }
                        />
                      </Tooltip>
                      {el.hasControls ? (
                        <Tooltip placement="top" title="Zablokuj">
                          <LockIcon onClick={() => handleToggleLockObjectById(el.id)} />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="top" title="Odblokuj">
                          <LockOpenIcon onClick={() => handleToggleLockObjectById(el.id)} />
                        </Tooltip>
                      )}
                      <Tooltip placement="top" title="Usuń">
                        <DeleteIcon onClick={() => handleDeleteObject(el.id)} />
                      </Tooltip>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default RenderLayers;
