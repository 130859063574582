import React, { useContext } from "react";
import s from "./ProjectList.module.scss";

import DashboardTopHeader from "../../../components/DashboardTopHeader/DashboardTopHeader";
import { useNavigate } from "react-router-dom";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { useTranslation } from "react-i18next";

import ProjectContext from "../../../core/context/ProjectContext";
import ProjectComponent from "../../../components/ProjectComponent.tsx/ProjectComponent";
const ProjectList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveGlobalState } = useContext(FabricJSContextNew);

  const { projects } = useContext(ProjectContext);

  const productHandler = (selectedView: any, fullJSON: any) => {
    setActiveGlobalState({
      canvasType: "project",
      fullJSON,
    });

    //since it is a project we pass down view_uuid to filter for layers inside view
    //then add layers from project
    // const productToShow = filterVersionToShow(
    //   selectedView?.view_uuid,
    //   selectedView?.uuid,
    //   fullJSON
    // );
    // handleLoadCanvasProject(canvasContext, productToShow.canvasJSON);
    navigate(`/design-studio/project/${fullJSON?.token}`);
  };

  return (
    <div className={s.container}>
      <DashboardTopHeader header={t("Projects")} navigateTo={"/projects"} />
      <div className={s.list_container}>
        {projects?.map((el: any, i: number) => {
          // if (i <= 3) {
          return (
            <ProjectComponent
              key={`Dashboard-project-list${i}`}
              project={el}
              handleClick={(variant) => productHandler(variant, el)}
              variant="medium"
              id={`Dashboard-project-list${i}`}
              componentOptions="1"
            />
          );
          // }
          // return null;
        })}
      </div>
    </div>
  );
};

export default ProjectList;
