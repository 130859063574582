import sortCanvasObjectsByLevel from "../SortCanvasObjectsByLevel";
import updateClipPath from "../UpdateClipPath";

const addBorderRadiusToWARect = (canvas: any, cornerRadius: any) => {
  if (canvas) {
    // Find the rectangle by its 'id'
    const rectWorkingArea = canvas
      .getObjects()
      .find((obj: any) => obj.id === "workingArea" && obj.type === "rect");

    // Check if the rectangle exists
    if (rectWorkingArea) {
      // Update the corner radius of the rectangle
      rectWorkingArea.set({
        rx: cornerRadius, // Set horizontal border radius
        ry: cornerRadius, // Set vertical border radius (same as horizontal for uniformity)
      });

      // Rerender the canvas to apply the changes
      updateClipPath(canvas, rectWorkingArea);
      sortCanvasObjectsByLevel(canvas);

      canvas.renderAll();
    }
  }
};

export default addBorderRadiusToWARect;
