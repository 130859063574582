import React, { useContext, useState, useEffect } from "react";
import s from "./OnboardWorkspace.module.scss";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../core/context/AuthContext";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import Back from "../../../components/Back/Back";
import { isEmptyString } from "../../../helper/validation";
import { useApiRequest } from "../../../core/api/useApiRequest";
import Cookies from "js-cookie";
const OnboardWorkspace = () => {
  const [input, setInput] = useState({
    name: "",
    slug: "",
  });
  const [isNameValid, setIsNameValid] = useState(true);
  const [isSlugValid, setIsSlugValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  const { t } = useTranslation();
  const { sendRequest, isLoading } = useApiRequest();
  const { setUser, user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFormValid(!isEmptyString(input.name) && !isEmptyString(input.slug));
  }, [input]);

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "name") {
      setIsNameValid(!isEmptyString(value));
    } else if (name === "slug") {
      setIsSlugValid(!isEmptyString(value));
    }
  };

  const handleCreateProfile = async () => {
    if (!isFormValid) return;

    const response: any = await sendRequest("post", "account/workspace", input);
    if (!response) return;

    setUser((prevState: any) => ({
      ...prevState,
      workspace: response?.data,
    }));

    navigate("/design-studio");
    console.log(response, " response");
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <span>{t("OnboardWorkspaceComponent.Your team")}</span>
          <span>{t("OnboardWorkspaceComponent.one")}</span>

          <TextField
            name="name"
            onChange={inputHandler}
            className={s.input}
            id="outlined-basic"
            label={t("Team name")}
            variant="outlined"
            error={!isNameValid}
          />
          <TextField
            name="slug"
            onChange={inputHandler}
            className={s.input}
            label={t("Address url")}
            variant="outlined"
            error={!isSlugValid}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={s.end_adorment}>
                    <span>.videlink.com</span>
                  </div>
                </InputAdornment>
              ),
            }}
          />
          <div className={s.button_wrapper}>
            <Back params={-1} />
            <div className={s.button_container}>
              <Button
                text={isLoading ? t("Loading") : t("Next")}
                type="button"
                handleClick={handleCreateProfile}
                disabled={!isFormValid}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="solid-line"></div>
          <span>
            {t("OnboardProfileComponent.one")}{" "}
            <Link to="/dashboard">{t("OnboardProfileComponent.two")}</Link>
          </span>
        </div>
      </div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default OnboardWorkspace;
