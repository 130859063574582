import React, { useContext, useEffect, useState } from "react";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { buildTree } from "../../../helper/buildFolderThree";
import AppContext from "../../../core/context/AppContext";
import { IFolderData } from "../../../types/generalTypes";
import Folder from "../Folder/Folder";
import { useNavigate, useParams } from "react-router-dom";
interface IFolderNodeTree {
  requestPath: string;
  routerPath?: string;
  folderPath: any;
  setFolderPath: any;
  type?: any;
}
const containerStyle: React.CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
};

const FolderNodeTree = ({
  requestPath,
  routerPath,
  folderPath,
  setFolderPath,
  type,
}: IFolderNodeTree) => {
  const navigate = useNavigate();
  const { folderToken } = useParams<{ folderToken?: string }>();
  const { sendRequest } = useApiRequest();
  const { forceFetchFolder, setCurrentFolder } = useContext(AppContext);
  const [folders, setFolders] = useState<IFolderData[]>([]);

  const currentFolder = folderPath.length > 0 ? folderPath[folderPath.length - 1] : null;

  const handleFolderClick = (folder: IFolderData) => {
    setFolderPath([...folderPath, folder]);
    if (!routerPath) return;
    navigate(`/${routerPath}/folder/${folder.token}`);
  };

  const fetchFolders = async (): Promise<void> => {
    try {
      const { data }: any = await sendRequest("get", `creator/2d/${requestPath}/categories`);
      const tree: any = buildTree(data);

      if (folderToken) {
        const updatedCurrentFolder = findFolderByToken(tree, folderToken);

        if (updatedCurrentFolder) {
          setFolderPath((prevPath: any) => {
            const newPath = [...prevPath];
            newPath[newPath.length - 1] = { ...updatedCurrentFolder };
            return newPath;
          });
          setCurrentFolder(updatedCurrentFolder);
        }
      } else {
        setFolders([...tree]);
      }
    } catch (err) {
      console.error("Error fetching folders:", err);
    }
  };

  useEffect(() => {
    fetchFolders();
    setCurrentFolder(currentFolder);
  }, [folderToken, forceFetchFolder]);

  return (
    <>
      <div style={containerStyle}>
        {(currentFolder ? currentFolder.children : folders)?.map((folder: IFolderData) => (
          <Folder
            handleClick={() => handleFolderClick(folder)}
            folder={folder}
            path={requestPath}
            key={folder.id}
          />
        ))}
      </div>
    </>
  );
};

const findFolderByToken = (folders: IFolderData[], token: string): IFolderData | null => {
  for (const folder of folders) {
    if (folder.token === token) {
      return folder;
    }

    if (folder.children) {
      const found = findFolderByToken(folder.children, token);
      if (found) return found;
    }
  }
  return null;
};

export default FolderNodeTree;
