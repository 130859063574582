import React, { useState, useCallback, useContext } from "react";
import s from "./GetQuoteModal.module.scss";
import ModalHeader from "../ModalHeader";
import { TextField } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";

import { useTranslation } from "react-i18next";
import Button from "../../Button/Button";
import { useApiRequest } from "../../../core/api/useApiRequest";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import LoadingState from "../../LoadingState/LoadingState";
import { isEmptyString, validateEmail } from "../../../helper/validation";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";
import AuthContext from "../../../core/context/AuthContext";
import NotLoggedIn from "./NotLoggedIn";

interface IGetQuoteModal {
  setCloseModal: any;
}

interface IUserInput {
  name: string;
  email: string;
  phone: string;
  message: string;
}

interface IErrors {
  name: boolean;
  email: boolean;
  phone: boolean;
  message: boolean;
}

const initialUserState: IUserInput = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const initialErrorState: IErrors = {
  name: false,
  email: false,
  phone: false,
  message: false,
};

const validateForm = (userInput: IUserInput): IErrors => {
  const errors: IErrors = { ...initialErrorState };

  if (!userInput.name) errors.name = true;
  if (!userInput.email || !validateEmail(userInput.email)) errors.email = true;
  if (!userInput.phone) errors.phone = true;
  if (!userInput.message) errors.message = true;

  return errors;
};

const GetQuoteModal: React.FC<IGetQuoteModal> = ({ setCloseModal }) => {
  const { user } = useContext(AuthContext);
  const [userInput, setUserInput] = useState<IUserInput>(initialUserState);
  const [errors, setErrors] = useState<IErrors>(initialErrorState);
  const { t } = useTranslation();

  const { sendRequest, isLoading } = useApiRequest();
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setUserInput((prevState) => ({ ...prevState, [name]: value }));

      // Validate individual fields
      if (name === "email") {
        setErrors((prevState) => ({ ...prevState, [name]: !validateEmail(value) }));
      } else {
        setErrors((prevState) => ({ ...prevState, [name]: value === "" }));
      }
    },
    []
  );

  const { activeGlobalState } = useContext(FabricJSContextNew);
  console.log(activeGlobalState);
  const notification = useNotification();
  const getQuoteHandler = useCallback(() => {
    const formErrors = validateForm(userInput);
    setErrors(formErrors);

    const isValid = Object.values(formErrors).every((error) => !error);

    if (isValid) {
      const response: any = sendRequest(
        "post",
        `/creator/2d/projects/${activeGlobalState.fullJSON.id}/ask-for-estimation`,
        userInput
      );
      if (response) {
        notification({
          id: uniqueID,
          type: "SUCCESS",
          message: "Email has been sent",
        });
        setCloseModal(false);
      }
    } else {
      console.log("Form has errors:", formErrors);
    }
  }, [userInput]);

  // Check if all fields are filled and email is valid
  const isFormValid =
    Object.values(userInput).every((value) => value !== "") && validateEmail(userInput.email);

  return (
    <>
      {user ? (
        <div className={s.container}>
          <ModalHeader header={t("GetQuoteModal.Header")} />
          <div className={s.form}>
            <TextField
              size="small"
              label={t("GetQuoteModal.Name")}
              onChange={handleInputChange}
              name="name"
              value={userInput.name}
              className={errors.name ? s.error : ""}
              error={errors.name}
            />
            <div className={s.input_container}>
              <TextField
                size="small"
                label={t("GetQuoteModal.Email")}
                onChange={handleInputChange}
                name="email"
                value={userInput.email}
                className={errors.email ? s.error : ""}
                error={errors.email}
              />
              <TextField
                size="small"
                label={t("GetQuoteModal.Number")}
                onChange={handleInputChange}
                type="number"
                name="phone"
                value={userInput.phone}
                className={errors.phone ? s.error : ""}
                error={errors.phone}
              />
            </div>
            <BaseTextareaAutosize
              placeholder={t("GetQuoteModal.Message")}
              onChange={handleInputChange}
              name="message"
              value={userInput.message}
              className={`${s.textarea} ${errors.message ? s.error : ""}`}
              aria-invalid={errors.message ? "true" : "false"}
            />
            <Button
              type="button"
              handleClick={getQuoteHandler}
              text={t("Send")}
              styles={{ maxWidth: "200px" }}
              isLoading={isLoading}
              disabled={!isFormValid}
            />
          </div>
        </div>
      ) : (
        <NotLoggedIn />
      )}
    </>
  );
};

export default GetQuoteModal;
