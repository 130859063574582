import React, { useContext, useEffect, useState } from "react";
import TopBar from "../../../components/Nav/TopBar/TopBar";
import LeftBar from "../../../components/Nav/LeftBar/LeftBar";
import FabricJSPreview from "./FabricJS/FabricJSPreview";
import { filterVersionToShow } from "../../../helper/filterVersionToShow";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { useParams } from "react-router-dom";
import { useApiRequest } from "../../../core/api/useApiRequest";
import LoadingState from "../../../components/LoadingState/LoadingState";
import { useTranslation } from "react-i18next";
import { filterVersionToShowPreview } from "../../../helper/filterVersionToShowPreview";
import handleLoadCanvasProject from "../fabric-functions/LoadProject";
import { updateCanvasObjectsList } from "../fabric-functions/UpdateCanvasObjectList";
const loadingStyle = {
  width: "100%",
  marginTop: "28%",
  marginBottom: "28%",
  boxSizing: "border-box",
};

const PreviewStudio = () => {
  const { t } = useTranslation();

  const { token } = useParams();
  const [projectId, setProjectId] = useState<number>();
  const { sendRequest, isLoading } = useApiRequest();

  const { canvasContext, activeGlobalState, setActiveGlobalState, setCanvasObjectList } =
    useContext(FabricJSContextNew);
  const fetchContentToQuote = async () => {
    try {
      const response: any = await sendRequest("get", `/creator/2d/projects/shares/${token}`);
      console.log(response, "fetchContentToQuote response");

      if (response && response.data && response.data.project_id) {
        setProjectId(response.data.project_id);
        fetchProject(response.data.project_id);
      } else {
        console.error("No project_id found in response");
      }
    } catch (error) {
      console.error("Error in fetchContentToQuote:", error);
    }
  };
  const fetchProject = async (projectId: number) => {
    try {
      if (projectId) {
        const responseTwo: any = await sendRequest("get", `/creator/2d/projects/${projectId}`);
        console.log(responseTwo, "fetchProject response");

        if (responseTwo && responseTwo?.data) {
          const { versions, fullJSON } = responseTwo?.data;
          if (versions && versions[0]) {
            console.log(versions[0], "versions[0]");
            console.log(fullJSON, "fullJSON");

            const projectToShow = filterVersionToShowPreview(
              versions[0].view_uuid,
              versions[0].uuid,
              responseTwo.data
            );

            if (projectToShow && canvasContext) {
              setActiveGlobalState({
                fullJSON: responseTwo?.data,
                selectedView: responseTwo?.data?.versions[0],
              });
              handleLoadCanvasProject(canvasContext, projectToShow.canvasJSON);
              updateCanvasObjectsList(canvasContext, setCanvasObjectList);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error in fetchProject:", error);
    }
  };

  useEffect(() => {
    fetchContentToQuote();
  }, [canvasContext]);

  return (
    <>
      <TopBar />
      {isLoading ? (
        <LoadingState styles={loadingStyle} text={t("Loading")} />
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <FabricJSPreview />
          </div>
        </>
      )}
    </>
  );
};

export default PreviewStudio;
