const createFollowerImage = (img: any, cloneSettings: any) => {
  return new Promise<fabric.Image>((resolve) => {
    img.clone((clonedImg: any) => {
      clonedImg.set({
        id: cloneSettings.id,
        name: cloneSettings.name,
        level: cloneSettings.level,
        opacity: 0.5,
        selectable: true,
        evented: false,
        clipPath: undefined,
        isClone: true,
      });
      resolve(clonedImg);
    });
  });
};
export default createFollowerImage;
