import React, { useContext, useState } from "react";
import s from "./ProductLeftBar.module.scss";

import { dummyGraphics } from "../../../../../dummy-data/graphics";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import Button from "../../../../Button/Button";
import { useTranslation } from "react-i18next";
import SlideUpModal from "../../../../Modals/Modal/SildeUpModal";
import CreateProduct from "../../../../Modals/CreateProduct/CreateProduct";
import ProductContext from "../../../../../core/context/ProductContext";
import ProductComponent from "../../../../ProductComponent/ProductComponent";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../../core/context/AuthContext";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import handleLoadCanvasProject from "../../../../../features/Fabric-JS/fabric-functions/LoadProject";
import FolderNodeTree from "../../../../FolderComponents/FolderNodeThree/FolderNodeTree";
import AppContext from "../../../../../core/context/AppContext";
import ExpandableFolderList from "../../../../FolderComponents/ExpandableFolderList/ExpandableFolderList";
const ProductsLeftBar = () => {
  const { canvasContext, setActiveGlobalState } = useContext(FabricJSContextNew);
  const [openProductCreate, setOpenProductCreate] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const correctPath = !!user ? "design-studio" : "free-editor";

  const handleCreateNewProduct = () => {
    if (!user) return;
    setOpenProductCreate(true);
  };
  const productHandler = (selectedView: any, wholeProduct: any) => {
    handleLoadCanvasProject(canvasContext, selectedView);
    setActiveGlobalState({ canvasType: "product", fullJSON: wholeProduct });
    navigate(`/design-studio/product/${wholeProduct?.token}`);
  };
  const [selectedFolder, setSelectedFolder] = useState<any>({ id: 0 });
  return (
    <>
      <div className={s.container}>
        <h4>{t("LastUsed")}</h4>

        <div className={s.products_container}>
          <FilterComponent
            endpoint="/creator/2d/templates"
            defaultSort="id"
            defaultPagination={5}
            isPagination={false}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProductComponent
                    key={`dashboard-product-products-recent-map${i}`}
                    product={el}
                    variant="2ex_small"
                    handleClick={(variant) => productHandler(variant, el)}
                    componentOptions="2"
                    id={`dashboard-product-products-recent-map${i}`}
                  />
                ))}
              </div>
            )}
          />
        </div>
        <div className={s.folder_div}>
          <ExpandableFolderList
            requestPath="templates"
            setSelectedFolder={setSelectedFolder}
            selectedFolder={selectedFolder}
          />
        </div>
        <div className={s.products_container}>
          <FilterComponent
            endpoint="/creator/2d/templates"
            defaultSort="id"
            defaultPagination={10}
            isPagination={true}
            category_id={selectedFolder?.id}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProductComponent
                    key={`dashboard-product-products-all-map${i}`}
                    product={el}
                    variant="2ex_small"
                    handleClick={(variant) => productHandler(variant, el)}
                    componentOptions="2"
                    id={`dashboard-product-products-all-map${i}`}
                  />
                ))}
              </div>
            )}
          />
        </div>

        <div className={s.button_container}>
          <Button
            type="button"
            text={t("New Product")}
            handleClick={() => handleCreateNewProduct()}
            disabled={!user}
          />
        </div>
      </div>
      <SlideUpModal open={openProductCreate} onClose={() => setOpenProductCreate(false)}>
        <CreateProduct setCloseModal={setOpenProductCreate} />
      </SlideUpModal>
    </>
  );
};

export default ProductsLeftBar;
