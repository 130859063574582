import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiRequest } from "../../../core/api/useApiRequest";
import Cookies from "js-cookie";

import AuthContext from "../../../core/context/AuthContext";
import LoadingState from "../../../components/LoadingState/LoadingState";
import { useTranslation } from "react-i18next";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";

const Activate = () => {
  const { sendRequest } = useApiRequest();
  const navigate = useNavigate();
  const { token } = useParams();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const notification = useNotification();
  console.log("Activation token:", token);

  const activateUser = async () => {
    const response: any = await sendRequest("post", "./auth/activation", { token: token });
    console.log("API response:", response);

    if (response && response.access_token) {
      Cookies.set("vl_access_token", response.access_token, {
        domain: ".videlink.com.pl",
        path: "/",
      });

      console.log("Access token set:", response.access_token);
      notification({
        type: "SUCCESS",
        id: uniqueID(),
        message: t("UserActivated"),
      });
      if (user && !user.subscription) {
        console.log("No subscription, navigating to payment plan");
        navigate("/onboard/payment-plan");
      } else {
        console.log("Has subscription, navigating to dashboard");
        navigate("/design-studio");
      }
    }
  };

  useEffect(() => {
    activateUser();
  }, [token]);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <LoadingState text={t("Activating")} />;
    </div>
  );
};

export default Activate;
